<template>
	<div class="container" v-show="show">
		<div class="section">
			<div class="flex-row align-top">
				<div class="square"></div>
				<div class="vertical-line ml10"></div>
				<div class="horizontal-line"></div>
				<div class="vertical-line"></div>
				<div class="square ml10"></div>
			</div>
			<div class="flex-row align-center space-between">
				<div class="left-line"></div>
				<div class="right-line"></div>
			</div>
			<div class="con-sec">
				<div class="main-title">区块链查证信息概览</div>
				<div class="flex-row align-center space-between mt20">
					<div class="sec-title">产品数据</div>
					<img src="../assets/icon_arrow.png" class="arrow-icon" />
				</div>
				<div class="good-sec">
					<div class="good-item">
						<div class="good-item-title">作品名称</div>
						<div class="good-item-con">{{head.productName}}</div>
					</div>
					<div class="good-item">
						<div class="good-item-title">创作人</div>
						<div class="good-item-con">{{head.author}}</div>
					</div>
					<div class="good-item">
						<div class="good-item-title">发行方</div>
						<div class="good-item-con">{{head.issuerName}}</div>
					</div>
					<div class="good-item">
						<div class="good-item-title">作品介绍</div>
						<div class="good-item-con">{{head.productDesc?head.productDesc:''}}</div>
					</div>
				</div>
	<!-- 			<div class="flex-row align-center space-between mt20">
					<div class="sec-title">主人信息</div>
					<img src="../assets/icon_arrow.png" class="arrow-icon" />
				</div>
				<div class="transfer-sec">
					<div class="transfer-item" >
						<div class="transfer-item-company">{{transactionInfo[0].ownerName}}</div>
						<div class="transfer-item-title">交易时间</div>
						<div class="transfer-item-con">{{transactionInfo[0].time}}</div>
						<div class="transfer-item-title">交易哈希</div>
						<div class="transfer-item-con">
							{{transactionInfo[0].hash}}
						</div>
					</div>

				</div> -->
				<div class="flex-row align-center space-between mt20">
					<div class="sec-title">发行信息</div>
					<img src="../assets/icon_arrow.png" class="arrow-icon" />
				</div>
				<div class="transfer-sec">
					<!-- <div class="transfer-item" v-for="(item,index) in transactionInfo" v-bind:key="index"> -->
					<div class="transfer-item" >
						<div class="transfer-item-company">{{transactionInfo[0].ownerName}}</div>
						<!-- <div class="transfer-item-title">交易时间</div> -->
						<div class="transfer-item-title">藏品生成时间</div>
						<div class="transfer-item-con">{{transactionInfo[0].time}}</div>
						<div class="transfer-item-title">交易哈希</div>
						<div class="transfer-item-con">
							{{transactionInfo[0].hash}}
						</div>
					</div>
				
				</div>
			</div>
			<div class="flex-row align-center space-between">
				<div class="left-line"></div>
				<div class="right-line"></div>
			</div>
			<div class="flex-row align-end">
				<div class="square"></div>
				<div class="vertical-line ml10"></div>
				<div class="horizontal-line"></div>
				<div class="vertical-line"></div>
				<div class="square ml10"></div>
			</div>
		</div>
		<div class="more-btn" @click="navMember()">更多数字藏品</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				head: {},
				transactionInfo: [],
				show: 0
			}
		},
		created() {
			const that=this;
			util.wxConfig();
			const productId = this.$route.query.productId;
			if (!productId) {
				this.$router.push('/')
			}
			Toast.loading({
				message: '加载中...',
				forbidClick: true,
			});
			let params = new FormData()
			params.append('productId', productId)
			this.axios.post(config.requestUrl + '/front/nftProduct/queryCertificate', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'Token': this.$store.state.user.token
				}
			}).then(response => {
				if (response.data.code == 0) {
					Toast.clear()
					that.show = 1;
					that.head = response.data.obj.head;
					let transactionInfo = response.data.obj.transactionInfo
					for (let i in transactionInfo) {
						// if(parseInt(i)+1 < transactionInfo.length){
						// 	transactionInfo[i].ownerName=util.formatStr(transactionInfo[i].ownerName)
						// 	// if (/^[\u4E00-\u9FA5]+$/.test(transactionInfo[i].ownerName)) {
						// 	// 	transactionInfo[i].ownerName ='*'+ transactionInfo[i].ownerName.substring(1)
						// 	// } 
						// }
					}
					that.transactionInfo = transactionInfo;
					// Toast.success('查询成功')
				} else {
					Toast.fail(response.data.msg);
				}
			}, response => {
				this.getBox = 0;
				Toast.fail('获取失败，请稍后重试');
			})
		},
		methods: {
			navMember() {
				this.$router.replace('/')
			},
		
		}
	}
</script>

<style scoped="scoped">
	.container {}

	.section {
		width: 590px;
		background: #FFFFFF;
		box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.28);
		margin: 83px auto 0 auto;
		padding: 20px;
		box-sizing: border-box;
	}

	.square {
		width: 20px;
		height: 20px;
		background: rgba(119, 171, 245, 0.5);
		border: 1px solid #77ABF5;
		box-sizing: border-box;
	}

	.ml10 {
		margin-left: 10px;
	}

	.vertical-line {
		width: 1px;
		height: 30px;
		background: rgba(119, 171, 245, 0.5);

	}

	.horizontal-line {
		width: 490px;
		height: 1px;
		background: rgba(119, 171, 245, 0.5);
	}

	.left-line {
		width: 30px;
		height: 1px;
		background: rgba(119, 171, 245, 0.5);
	}

	.right-line {
		width: 30px;
		height: 1px;
		background: rgba(119, 171, 245, 0.5);
	}

	.con-sec {
		padding: 20px;
		border-right: 1px solid rgba(119, 171, 245, 0.5);
		border-left: 1px solid rgba(119, 171, 245, 0.5);
	}

	.main-title {
		font-size: 30px;
		font-weight: bold;
		color: #77ABF5;
		line-height: 42px;
		letter-spacing: 2px;
		text-align: center;
	}

	.mt20 {
		margin-top: 20px;
	}

	.sec-title {
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.arrow-icon {
		width: 22px;
		height: 22px;
	}

	.good-sec {
		width: 510px;
		background: rgba(119, 171, 245, 0.1);
		border: 1px solid #77ABF5;
		padding: 5px 20px 20px 20px;
		box-sizing: border-box;
		margin-top: 10px;
	}

	.good-item {
		margin-top: 20px;
	}

	.good-item-title {
		font-size: 24px;
		color: #666666;
		line-height: 33px;
		letter-spacing: 1px;
	}

	.good-item-con {
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		margin-top: 10px;
		width: 450px;
	}

	.transfer-sec {
		width: 510px;
		background: rgba(119, 171, 245, 0.1);
		border: 1px solid #77ABF5;
		padding: 25px 25px 25px 40px;
		box-sizing: border-box;
		margin-top: 10px;
	}

	.transfer-item {
		margin-top: 40px;
		position: relative;
	}

	.transfer-item:first-child {
		margin-top: 0;
	}

	.transfer-item-company {
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.transfer-item-title {
		font-size: 24px;
		color: #666666;
		line-height: 33px;
		letter-spacing: 1px;
		margin-top: 20px;
	}

	.transfer-item-con {
		width: 443px;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		word-wrap: break-word;
		word-break: break-all;
	}

	.transfer-item::before {
		content: "";
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background-color: #91c2fc;
		position: absolute;
		left: -24px;
		top: 15px;
	}

	.transfer-item::after {
		content: "";
		width: 2px;
		height: calc(100% + 40px);
		background-color: #91c2fc;
		position: absolute;
		top: 25px;
		left: -20px;
	}

	.transfer-item:last-child::after {
		content: "";
		width: 0px;
	}

	.more-btn {
		width: 280px;
		height: 60px;
		background: #FFFFFF;
		border-radius: 30px;
		border: 1px solid #77ABF5;
		font-size: 28px;
		color: #77ABF5;
		line-height: 60px;
		letter-spacing: 1px;
		text-align: center;
		margin: 30px auto;
	}
</style>

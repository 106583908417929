<template>
	<div id="app">
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" />
	</div>
</template>
<script>
	export default {
		name: "app",
		// mounted() {
		// 	console.log('app mounted')
		// },
		created() {
			document.title = "万物数字藏品"
		},
		methods: {
			setRem() {
				// 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
				let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
				//得到ht  ml的Dom元素
				let htmlDom = document.getElementsByTagName('html')[0];
				if (htmlWidth >= 450) {
					//设置根元素字体大小
					htmlDom.style.fontSize = 22 + 'px';
				} else {
					//设置根元素字体大小
					htmlDom.style.fontSize = htmlWidth / 20 + 'px';
				}
			}
		}
	}
</script>
<style>
	html {
		margin: 0 !important;
		padding: 0 !important;
	}

	body {
		margin: 0 !important;
		padding: 0 !important;
		-webkit-text-size-adjust: 100% !important;
		text-size-adjust: 100% !important;
		-moz-text-size-adjust: 100% !important;
	}

	html,
	body,
	#app {
		height: 100%;
	}

	.flex-row {
		display: flex;
		flex-direction: row;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.align-center {
		align-items: center;
	}

	.align-top {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.space-between {
		justify-content: space-between;
	}

	.space-around {
		justify-content: space-around;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.relative {
		position: relative;
	}

	.flex-end {
		justify-content: flex-end;
	}

	/*按钮样式开始  */

	button {
		margin: 0;
		padding: 0;
		border: none;
		background: none;
		border-radius: none;
	}

	button::after {
		content: none;
	}

	button[plain] {
		border: none;
	}

	/*按钮样式结束  */

	image {
		vertical-align: middle;
	}

	/* 多行溢出 */

	.more-ellipsis {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}

	/* 蒙层 */
	.model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1000;
	}

	/* 底部导航开始*/
	.tab-footer-sec {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 20;
		background: #FFFFFF;
		width: 750px;
		height: 88px;
		/* border-top: 1px solid rgba(0, 0, 0, 0.25); */
	}

	.tab-footer-btn {
		width: 250px;
		height: 88px;
		background: #FFFFFF;
		font-size: 20px;
		font-weight: bold;
		color: #000000;
		line-height: 20px;
		box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.25);
	}

	.tab-footer-btn-title {
		margin-top: 58px;
	}

	.tab-no-active-img {
		width: 48px;
		height: 48px;
		margin: 5px 0;
	}

	.tab-active-img {
		width: 93px;
		height: 93px;
		position: absolute;
		top: -29px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}


	/* 底部导航结束*/

	.bianhao {
		font-size: 20px;
		font-weight: bold;
		color: rgba(255, 255, 255);
		line-height: 28px;
		position: absolute;
		top: 109px;
		right: -70px;
		transform: rotate(90deg);
	}

	.bianhao2 {
		font-size: 20px;
		font-weight: bold;
		color: rgba(255, 255, 255);
		line-height: 28px;
		position: absolute;
		top: 109px;
		right: -75px;
		transform: rotate(90deg);
		white-space: nowrap;
	}
</style>

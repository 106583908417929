<template>
	<div class="container">
		<div class="item flex-row align-center">
			<div>盲盒id:</div>
			<input v-model="id" type="number" placeholder="请输入盲盒id">
		</div>
		<div class="item flex-row align-center">
			<div>执行次数:</div>
			<input v-model="num" type="number" placeholder="请输入执行次数">
		</div>
		<div class="item flex-row align-center">
			<div>执行间隔(s):</div>
			<input v-model="time" type="number" placeholder="请输入执行间隔">
		</div>
		<div class="number">执行次数:{{runNum}}</div>
		<div class="number">当前状态:{{isStart==1?'开始':'结束'}}</div>
		<div class="error" v-if="errorReason!=''">遇到错误停止:{{errorReason}}</div>
		<div class="btn-wrap flex-row align-center space-around">
			<div class="btn" @click.stop="start">开始</div>
			<div class="btn btn-false" @click.stop="stop">结束</div>
			<!-- <div class="btn">获取剩余数量</div> -->
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				id: '', //盲盒id
				num: '', //执行次数
				isStart: 0, //是否开始
				time: 2, //执行间隔
				quet: 0, //盲盒剩余数量
				runNum: 0, //执行次数
				runData: {
					id: '', //盲盒id
					num: '', //执行次数
					time: 2, //执行间隔
				},
				errorReason: '', //错误原因
			}
		},
		methods: {
			start() {
				if(!this.id){
					Toast('请输入盲盒id');
					return;
				}
				if(!this.num){
					Toast('请输入执行次数');
					return;
				}
				if (this.isStart == 1) return;
				this.isStart = 1;
				this.runNum=0;
				this.runData = {
					id: this.id,
					num: this.num,
					time: this.time
				}
				this.errorReason="";
				this.buy();
			},
			stop() {
				if (this.isStart == 0) return;
				this.isStart = 0;
			},
			buy() {
				if (this.isStart == 0) return;
				if (this.runData.num <= this.runNum) {
					this.isStart = 0;
					Toast.clear();
					return;
				}
				const that = this
				let params = new FormData()
				params.append('blindBoxId', this.id)
				params.append('buyType', 1)
				params.append('payType', 1)
			
				this.axios.post(config.requestUrl + '/front/order/createOrderByBlindBox', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.runNum = that.runNum + 1;
						setTimeout(() => {
							that.buy();
						}, parseInt(that.runData.time) * 1000)
					} else if (response.data.code == -7) {
						that.isStart = 0;
						that.errorReason = "需要输入验证码"
					} else {
						that.isStart = 0;
						that.errorReason = response.data.msg
					}
				}, response => {
					that.errorReason = "网络错误";
				})
			}
		},
		mounted() {
			if(config.env == 1){
				this.$router.replace('/')
			}
			this.id=this.$route.query.id
		},
		beforeRouteLeave(to, from, next) {
			this.isStart=0;
			next();
		}
	}
</script>

<style scoped="scoped">
	.item {
		width: 700px;
		margin: 30px auto 0;
		font-size: 28px;
	}

	.item div {
		width: 140px;
	}

	.item input {
		width: 500px;
	}

	.btn-wrap {
		margin-top: 200px;
	}

	.btn {
		width: 200px;
		height: 80px;
		font-size: 28px;
		text-align: center;
		line-height: 80px;
		border-radius: 20px;
		background: blue;
		color: #ffffff;
	}

	.btn-false {
		background: gray;
	}

	.number {
		width: 100%;
		text-align: center;
		font-size: 28px;
		margin: 60px auto;
	}

	.error {
		width: 100%;
		text-align: center;
		font-size: 28px;
		color: red;
		margin: auto;
	}
</style>

<template>
	<div class="container" ref="getheight">
		<div class="header flex-row align-center">
			<div class="tab flex-column align-center justify-center" @click="changeTab(1)">
				<div class="tab-test">已开盒</div>
				<div class="tab-line" v-if="tab == 1"></div>
			</div>
			<div class="tab flex-column align-center justify-center" @click="changeTab(2)">
				<div class="tab-test">未开盒</div>
				<div class="tab-line" v-if="tab == 2"></div>
			</div>
		</div>
		<div class="cp-wrap flex-row align-center flex-wrap space-between" v-if="tab == 1">
			<div v-for="(item,index) in list" v-bind:key="index" class="wrap">
				<img @click.stop="navList(item.id)"  :src="item.mianPic" :data-index="index" class="cp-img">
				<div class="goods-nums">x {{item.count}}</div>
			</div>
			
		</div>
		<div class="cp-wrap flex-row align-center flex-wrap space-between" v-if="tab == 2">
			<div v-for="(item,index) in list" v-bind:key="index">
				<img @click.stop="openCard(index)" v-if="item.isOpen == '是'" :src="item.productPic2" :data-index="index"
					:data-src="item.productPic2" :v-real-img="item.productPic2" class="cp-img">
				<img @click.stop="openCard(index)" v-if="item.isOpen == '否'" src="../assets/manghe.png"
					:data-index="index" data-src="../assets/manghe.png" mode="aspectFill" class="cp-img2">
			</div>
		</div>
		<div class="open-blind" v-if="tab ==2" @click.stop="openAllBlind">一键开盒</div>
		<div class="empty" v-if="empty">还没有藏品</div>
		<div class="tab-footer-sec flex-row align-center">
			<div class="flex-column align-center tab-footer-btn" @click="navHome()">
				<img src='../assets/box.png' class="tab-no-active-img" />
				<div>抽盒</div>
			</div>
			<div class="flex-column align-center tab-footer-btn relative" @click.stop="">
				<img src='../assets/collect-selected.png' class="tab-active-img" />
				<div class="tab-footer-btn-title">藏品</div>
			</div>
			<div class="flex-column align-center tab-footer-btn " @click.stop="navMember()">
				<img src='../assets/mine_icon.png' class="tab-no-active-img" />
				<div>我的</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				tab: 1,
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: 0,
				windowHeight: 0,
				offsetHeight: 0,
				empty: false
			}
		},
		mounted() {
			util.wxConfig();
			window.addEventListener('scroll', this.onScroll, false);
			this.windowHeight = window.innerHeight; //窗口高度
			this.offsetHeight = this.$refs.getheight.offsetHeight;
		},
		beforeRouteEnter(to, from, next) {
			console.log(from)
			if (from.name == 'collectlist') {
				next(that => {
					console.log(that.tab)
					if (that.tab == 2) {
						that.list = [];
						that.more = true;
						that.pageNum = 1;
						that.empty = false;
						that.getProListByOpenStatusIsNo();
					}
				});
			} else {
				next(that => {
					that.tab = 1;
					that.list = [];
					that.more = true;
					that.pageNum = 1;
					that.empty = false;
					that.getProListByOpenStatusIsOk();
				});
			}
		},
		methods: {
			navHome() {
				this.$router.replace('/')
			},
			navList(id){
				this.$router.push({
					path:'/collectlist',
					query:{
						id
					}
				})
			},
			navMember() {
				console.log('1231')
				this.$router.replace('/member')
			},
			changeTab(tab) {
				const that = this;
				if (tab == that.tab) return;
				that.tab = tab;
				that.list = [];
				that.more = true;
				that.pageNum = 1;
				that.empty = false;
				if (tab == 1) {
					that.getProListByOpenStatusIsOk();
				} else {

					that.getProListByOpenStatusIsNo();
				}
			},
			getProListByOpenStatusIsNo() {
				let a = 1;
				let b = 1;
				if (a == b) {
					if (!this.more) {
						Toast.fail('没有更多了');
						return;
					}
					if (this.loading) return;
					this.loading = 1;
					let params = new FormData()
					params.append('pageNum', this.pageNum);
					params.append('pageSize', this.pageSize);
					this.axios.post(config.requestUrl + '/front/user/getProListByOpenStatusIsNo', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': this.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (this.tab == 2) {
								let list = this.list;
								let newList = response.data.obj.list;
								for (let i in newList) {
									newList[i].show = false;
									// let arr = newList[i].productPic.split('/');
									// let str = arr.pop();
									// newList[i].productPic2 = config.imgUrl + str;
									// let createTime = newList[i].updateTime.replace(/-/g, "/");
									// createTime=new Date(createTime);
									// createTime = new Date(createTime.setSeconds(createTime.getSeconds() + 210));
									// if(newList[i].isOpen=='否' && util.compareTime(createTime)){
									// 	newList[i].show=true;
									// }
									// if(!newList[i].show){
									// 	console.log(createTime)
									// 	console.log(newList[i].show);
									// }
								}
								if (response.data.obj.isLastPage) this.more = false;
								list = list.concat(newList);
								this.pageNum = this.pageNum + 1;
								this.list = list;
								if (this.list.length == 0) {
									this.empty = true;
								}
								// this.$store.commit('setGoodsList', list)
								this.offsetHeight = this.$refs.getheight.offsetHeight
								Toast.clear()
								this.$forceUpdate();
							}
						} else {
							let msg = response.data.msg;
							const str = "未获取到用户信息";
							const str2 = "登录已失效"
							if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
							}
							Toast.fail(response.data.msg);
						}
						this.loading = 0;
					}, response => {
						this.loading = 0;
						Toast.fail('获取失败，请稍后重试');
					})
				} else {
					Toast.loading({
						message: '加载中',
						forbidClick: true,
						duration: 10000,
					});
				}
			},
			getProListByOpenStatusIsOk() {
				if (!this.more) {
					Toast.fail('没有更多了');
					return;
				}
				if (this.loading) return;
				this.loading = 1;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				this.axios.post(config.requestUrl + '/front/user/getProListByOpenStatusIsOk', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (this.tab == 1) {
							let list = this.list;
							let newList = response.data.obj.list;
							// for (let i in newList) {
							// 	newList[i].show = true;
							// 	let arr = newList[i].productPic.split('/');
							// 	let str = arr.pop();
							// 	newList[i].productPic2 = config.imgUrl + str;
							// }
							if (response.data.obj.isLastPage) this.more = false;
							list = list.concat(newList);
							this.pageNum = this.pageNum + 1;
							this.list = list;
							if (this.list.length == 0) {
								this.empty = true;
							}
							// this.$store.commit('setGoodsList', list)
							this.offsetHeight = this.$refs.getheight.offsetHeight
							Toast.clear()
							this.$forceUpdate();
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
					this.loading = 0;
				}, response => {
					this.loading = 0;
					Toast.fail('获取失败，请稍后重试');
				})
			},
			openAllBlind() {
				const that = this;
				Dialog.confirm({
						title: '一键开盒',
						message: '确认要拆开所有盲盒？',
					})
					.then(() => {
						Toast.loading({
							message: '拆盒中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						that.axios.post(config.requestUrl + '/front/blindBox/oneKeyOpenBox', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								that.list = [];
								that.more = true;
								that.pageNum = 1;
								that.empty = false;
								that.getProListByOpenStatusIsNo();
								Toast.success('拆盒成功');
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast.fail(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast.fail('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //页面滚动高度

				if (this.offsetHeight < scroll + this.windowHeight + 150) {
					if (this.more && !this.loading) {
						if (this.tab == 1) {
							this.getProListByOpenStatusIsOk();
						} else {
							this.getProListByOpenStatusIsNo();
						}
					}
				}
			},
			openCard(index) {
				this.card = this.list[index];
				this.card.index = index;
				this.$router.push({
					path: "/share",
					query: this.card
				});
			},
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		background: #222222;
		min-height: 100%;
		padding-top: 128px;

		box-sizing: border-box;
	}

	.header {
		width: 750px;
		height: 88px;
		background: #000000;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.tab {
		width: 375px;
		height: 88px;
		position: relative;
	}

	.tab-test {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 42px;
		letter-spacing: 2px;
	}

	.tab-line {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 80px;
		height: 6px;
		background: #FFFFFF;
		border-radius: 4px;
	}

	.cp-wrap {
		width: 690px;
		margin: auto auto;
		padding-bottom: 128px;
		position: relative;
	}

	.cp-img {
		width: 330px;
		height: 400px;
		margin-bottom: 30px;
	}

	.cp-img2 {
		width: 330px;
		height: 330px;
		padding: 35px 0;
		margin-bottom: 30px;
	}

	.empty {
		width: 100%;
		height: 100px;
		text-align: center;
		/* line-height: 100px; */
		font-size: 34px;
		color: #FFFFFF;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.open-blind {
		width: 423px;
		height: 70px;
		background: #FF5E38;
		border-radius: 38px;
		font-size: 34px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 70px;
		text-align: center;
		position: fixed;
		bottom: 120px;
		left: 0;
		right: 0;
		margin: auto;
	}
	.wrap{
		position: relative;
	}
	.goods-nums {
		position: absolute;
		top: 349px;
		right: 0;
		min-width: 60px;
		height: 60px;
		padding-left: 15px;
		padding-right: 10px;
		background: rgba(0, 0, 0, 0.6);
		border-radius: 50px 0 0 0;
		text-align: center;
		line-height: 60px;
		font-size: 30px;
		color: #FFFFFF;
	}
</style>

<template>
	<div class="container">
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="yePay(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<PublicWrap :msg="msg" v-show="showPublic" v-on:close-public="closePublic()" />
		<img src="../assets/detail_bg.png" class="detail-bg-img" />
		<div class="chou-sec flex-column align-center" style="margin-top:10px;">
			<div class="flex-row align-center justify-center time-sec" style="background:rgba(255, 255, 255, 0.15)">
				<img src="../assets/loudou.png" class="loudou-img" />
				<div class="chou-time" >剩余数量：{{info.surplusQuantity}}个</div>
			</div>
			<div class="manghe-sec flex-row align-center relative">
				<div class="flex-column align-center relative model70">
					<div class="manghe-title">{{info.name}}</div>
					<img :src="info.mianPic" class="manghe-bg-img" />
					<img :src="info.detailsPic" v-if="info.boxType == 1" class="box-detailimg" alt="">
					<div class="good-big-img-list" v-else>
						<div class="flex-row align-center flex-wrap good-big-img-item ">
							<div hover-class='good-bg-img-hover' v-for="(item,index) in 9" v-bind:key="index"
								class="relative good-bg-img flex-column justify-center " style="background:#E2DAFF">
								<div class="triangle-topleft"></div>
								<div class="good-index">{{index + 1}}</div>
								<!-- 如果商品已售出 -->
								<!-- <img src="../assets/good4.png" v-if="index%2 == 0" class="good-img1 w64" /> -->
								<!-- 商品还未被抽取 -->
								<img src="../assets/good1.png" class="good-img1" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="for-mi">
				<img src="../assets/mi.png" class="mi-img" bindtap="closeJiemiModel" />
				<div class="mi-desc">{{info.summary}}</div>
			</div>
		</div>
		<div class="play-sm-sec">
			<div class="flex-row align-center flex-end pick-sec">
				<!-- <div class="buy-one" @click="toBuy(1)">买一个</div>
				<div class="buy-more" @click="toBuy(9)">买整套</div> -->
				<!-- <div class="pre-buy" @click="toBuy(1)">买一个</div> -->
				<div class="pre-buy" @click="toBuy(1)">购买</div>
				<div class="pre-buy" v-if="env==0" @click="navTest()">脚本</div>
			</div>
		</div>
		<div class="base-info-wrap">
			<div class="base-info ">
				<div class="flex-row align-center">
					<div class="base-info-t">发行方：</div>
					<div class="base-info-c">{{info.issuerName}}</div>
				</div>
				<div class="flex-row align-center mt10">
					<div class="base-info-t">发行数量：</div>
					<div class="base-info-c">{{info.boxType== 1?info.quantity:info.boxType== 2?info.quantity*5:info.quantity*9}}个</div>
				</div>
				<div class="flex-row align-top mt10">
					<div class="base-info-t">合约地址：</div>
					<div class="flex-column">
						<div class="base-info-c">{{addressInfo}}</div>
					</div>
				</div>
				<div class="flex-row align-center mt10">
					<div class="base-info-t">权益：</div>
					<div class="base-info-c">{{info.rightsDesc}}</div>
				</div>
			</div>
		</div>
		<div class="other-info-wrap" v-html="info.details">
		</div>
		<div class="model" v-if="buyModal" @click.stop="closeBuy">
		</div>
		<div class="pick-good-model" v-if="buyModal">
			<div class="pick-title">{{info.name}}</div>
			<div class="flex-row align-center pick-item">
				<div class="pick-item-left">单价：</div>
				<div class="pick-item-right">{{info.price}}元</div>
			</div>
			<div class="flex-row align-center pick-item">
				<div class="pick-item-left">数量：</div>
				<div class="pick-item-right" style="color:#FF8B70">{{num}}</div>
			</div>
			<div class="flex-row align-center pick-item">
				<div class="pick-item-left">合计：</div>
				<div class="pick-item-right">{{price}}元</div>
			</div>
			<div class="flex-row pick-item-tishi align-start">
				<div class="pick-item-left">提示：</div>
				<!-- <div class="pick-item-right">{{msg}}</div> -->
				<div class="pick-item-right">盲盒类商品付款后即完成在线拆盒，依据 《网络购买商品七日无理由退货暂行办法》 第七条（二）规定，不适用七日无理由退货。</div>
			</div>
			<div class="pick-confirm-btn" @click="choosePay">确认上述信息并支付</div>
		</div>
		<div class="pay-wrap" v-if="payModel">
			<div class="pay-type-wrap flex-row align-center space-around">
				<div class="pay-type flex-column align-center" @click="choosePayType(1)">
					<img src="../assets/ye.png" class="pay-img" />
					<div class="pay-text">余额</div>
					<div class="pay-radio" :class="payType == 1?'pay-radio-sel':''"></div>
				</div>
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(2)">
					<img src="../assets/wx.png" class="pay-img" />
					<div class="pay-text">微信</div>
					<div class="pay-radio" :class="payType == 2?'pay-radio-sel':''"></div>
				</div> -->
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(3)">
					<img src="../assets/zfb.png" class="pay-img" />
					<div class="pay-text">支付宝</div>
					<div class="pay-radio" :class="payType == 3?'pay-radio-sel':''"></div>
				</div> -->
			</div>
			<div class="btn-pay" @click.stop="confirmPay()">支付</div>
		</div>
		<div class="model" v-if="payModel" @click.stop="closePay">
		</div>


		<div class="model" v-if="confirmModal" @click.stop="closeBuyModal"></div>
		<div class="buy-confirm-wrap" v-if="confirmModal">
			<div class="bc-top flex-row align-center">
				<div class="bc-ti">选择购买数量</div>
				<div class="close-bc" @click.stop="closeBuyModal">关闭</div>
			</div>
			<!-- 	<div class="flex-row align-top tips-wrap">
				<img src="../assets/icon-tips.png" class="icon-tips" alt="">
				<div class="tips">您购买的电子凭证有效期截止2025年12月31日，付款七天内无理由退货，七天后可转让不可退货。</div>
			</div> -->

			<div class="num-con flex-row align-center justify-center">
				<div class="add" @click.stop="reduce()">-</div>
				<input type="text" onkeyup="value=value.replace(/[^\d]/g,'')"
					oninput="if(value.length > 4)value = value.slice(0,4)" class="num" v-model="num">
				<div class="reduce" @click.stop="add()">+</div>
			</div>

			<div class="confirm-btn" @click.stop="confirmBuy()">确认</div>
		</div>

	</div>
</template>

<script>
	import LoginWrap from '@/components/Login.vue';
	import wx from 'weixin-js-sdk'

	import config from '../config/config.js';
	import util from '../config/util.js';
	import PublicWrap from '@/components/Public.vue';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				buyModal: false,
				id: '',
				info: '',
				price: 0,
				num: 1,
				showLogin: false,
				orderInfo: {},
				msg: '',
				getPayResInteval: '',
				count: 1,
				addressInfo: '',
				payModel: false,
				payType: 1,
				wxH5Pay: 0, //是否开始了其他浏览器h5支付
				djInt: '',
				showPublic: 0,
				isStart: 0,
				isBuy: 0,
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				env: config.env,
				confirmModal: false,
			}
		},
		components: {
			LoginWrap,
			PublicWrap
		},
		methods: {
			confirmBuy() {
				this.confirmModal = false
				this.price = util.accMul(this.info.price, this.num);
				this.$data.buyModal = true;
			},
			closeBuyModal() {
				this.confirmModal = 0
			},
			add() {
				if (Math.abs(parseInt(this.num)) < 9999) this.num = Math.abs(parseInt(this.num)) + 1
			},
			reduce() {
				if (Math.abs(parseInt(this.num)) > 1) this.num = Math.abs(parseInt(this.num)) - 1
			},
			navTest() {
				if (!this.$store.state.user.token) {
					this.login();
				} else {
					this.$router.push('/test?id=' + this.id)
				}
			},
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
			},
			closeBuy() {
				this.$data.buyModal = false;
			},
			toBuy(num) {
				// if (!this.$store.state.user.token) {
				// 	this.login();
				// }else {
				// 	this.price = this.info.price * num;
				// 	this.num = num;
				// 	this.$data.buyModal = true;
				// }
				if (!this.$store.state.user.token) {
					this.login();
				} else if (this.info.surplusQuantity <= 0) {
					this.msg = "请关注公众号接收发售提醒"
					this.openPublic();
				} else {
					this.checkBuyInfo(num);
				}
				// else if (!this.isStart) {
				// 	this.msg = "请关注公众号接收发售提醒"
				// 	this.openPublic();
				// } else {
				// 	this.price = this.info.price * num;
				// 	this.num = num;
				// 	this.$data.buyModal = true;
				// }
			},
			openPublic() {
				this.showPublic = true;
				this.$forceUpdate()
			},
			closePublic() {
				this.showPublic = false;
				this.$forceUpdate()
			},
			getDetail() {
				const that = this;
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				let params = new FormData()
				params.append('blindBoxId', that.id)
				that.axios.post(config.requestUrl + '/front/blindBox/getInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let info = response.data.obj;
						info.surplusQuantity = parseInt(info.surplusQuantity) < 0 ? 0 : info.surplusQuantity;
						that.info = info;
						let arr = that.info.mianPic.split('/');
						let str = arr.pop();
						that.info.mianPic = config.imgUrl + str;
						// that.info.startTime = that.info.startTime.replace(/-/g, "/");
						// that.info.startTimeFormat = util.formatTime(that.info.startTime);
						// that.info.isStart = util.compareTime(that.info.startTime)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			checkBuyInfo(num) {
				const that = this;
				let params = new FormData()
				params.append('blindBoxId', that.id)
				params.append('buyType', 1)
				that.axios.post(config.requestUrl + '/front/blindBox/checkBuyInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.price = this.info.price * num;
						this.num = num;
						this.confirmModal = 1;
					} else if (response.data.code == -1) {
						this.msg = "请关注公众号接收发售提醒"
						this.openPublic();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			queryContractAddress() {
				let params = new FormData()
				params.append('period', 1)
				this.axios.post(config.requestUrl + '/front/nftProduct/queryContractAddress', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.addressInfo = response.data.obj;
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			choosePay() {
				this.type = 1;
				this.payModel = true;
			},
			confirmPay() {
				// window.location.href="https://qr.alipay.com/bax02955joj7xhlnmecg30c6"

				console.log('confirmPay' + this.type)
				if (this.payType == 1) {
					this.yePay();
					// this.getWalletByCurrencyId();
				} else if (this.payType == 2) {
					this.wxPay();
					console.log("微信支付")
				} else if (this.payType == 3) {
					this.zfbPay();
				}

			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			yePay(verify) {
				console.log('aaa')

				const that = this
				if (that.isBuy) return;
				this.isBuy = true;
				Toast.loading({
					message: '正在抢单中。。。',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('blindBoxId', this.id)
				// params.append('buyType', this.num == 1 ? 1 : 2)
				params.append('buyType', 3)
				params.append('currentBuyCount', this.num)
				params.append('payType', 1)
				if (verify) {
					params.append('codeValue', that.verifyCode)
					params.append('key', that.verifyKey)

				}
				let query = {
					blindBoxId: this.id,
					buyType: this.num
				}
				this.axios.post(config.requestUrl + '/front/order/createOrderByBlindBox', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.orderInfo = response.data.obj;
						// this.getYePayInfo()
						that.payModel = false;
						Toast.success('支付成功')
						setTimeout(() => {
							if (that.info.boxType == 1 || that.info.boxType == 2) {
								that.$router.replace({
									path: "/collect",
								})
							} else {
								that.$router.replace({
									path: "/open",
									query: {
										orderId: that.orderInfo.id
									}
								})
							}
						}, 1200)
					} else if (response.data.code == -7) {
						Toast.clear()
						that.payModel = false;
						that.getVerifyCode()
					} else {
						console.log(response)
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
					this.isBuy = false;
				}, response => {
					this.isBuy = false;
					Toast.fail('抢单失败，点支付继续抢单');
				})
			},
			wxPay() {
				const that = this
				Toast.loading({
					message: '正在获取支付参数...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('blindBoxId', this.id)
				params.append('buyType', this.num == 1 ? 1 : 2)
				let query = {
					blindBoxId: this.id,
					buyType: this.num
				}
				this.axios.post(config.requestUrl + '/front/order/createOrderByBlindBox', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.msg = this.msg + "提交订单:成功" + "\n";
					if (response.data.code == 0) {
						this.orderInfo = response.data.obj;
						this.getPayInfo()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			zfbPay() {
				const that = this
				Toast.loading({
					message: '正在抢单中。。。',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('blindBoxId', this.id)
				params.append('buyType', this.num == 1 ? 1 : 2)
				let query = {
					blindBoxId: this.id,
					buyType: this.num
				}
				this.axios.post(config.requestUrl + '/front/order/createOrderByBlindBox', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.msg = this.msg + "提交订单:成功" + "\n";
					if (response.data.code == 0) {
						Toast.clear();
						this.orderInfo = response.data.obj;
						this.$router.push({
							path: "/zfb",
							query: {
								orderId: this.orderInfo.id,
								token: this.$store.state.user.token,
								type: 1
							}
						});
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('抢单失败，点支付继续抢单');
				})
			},
			getWalletByCurrencyId() {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let balance = response.data.obj.using;
						if (that.price > balance) {
							Toast.fail('余额不足');
						} else {
							this.yePay();
						}
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('抢单失败，点支付继续抢单');
				})
			},
			getPayInfo() {
				const that = this;
				let params = new FormData()
				params.append('orderId', this.orderInfo.id);
				params.append('payType', util.isWx() ? 1 : 2)
				this.axios.post(config.requestUrl + '/front/weiXinPay', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.msg = this.msg + "getPayInfo:结果成功" + "\n";
						Toast.clear();
						console.log(response)
						if (util.isWx()) {
							let payData = response.data.obj.jsapiResult;
							wx.ready(function() {
								wx.chooseWXPay({
									timestamp: payData.timeStamp,
									nonceStr: payData.nonceStr,
									package: payData.package,
									signType: payData.signType,
									paySign: payData.sign, // 支付签名
									success: function(res) {
										// 支付成功后的回调函数
										Toast.loading({
											message: '正在获取支付结果...',
											forbidClick: true,
											duration: 0,
										});
										that.getPayRes();
									},
									fail() {
										Toast.fail('取消支付')
									}
								});
							});
						} else {
							console.log(response.data.obj.h5PayResult)
							this.wxH5Pay = 1;
							this.payModel = false;
							this.buyModal = false;
							window.location.href = response.data.obj.h5PayResult.mweb_url
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			getYePayInfo() {
				const that = this;
				let params = new FormData()
				params.append('orderId', this.orderInfo.id);
				params.append('bizType', 1)
				params.append('currencyId', 1)
				this.axios.post(config.requestUrl + '/front/accountBalancePay', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					console.log(response)
					if (response.data.code == 0) {
						that.payModel = false;
						that.showVerify = false;
						Toast.success('支付成功')
						setTimeout(() => {
							that.$router.replace({
								path: "/open",
								query: {
									orderId: that.orderInfo.id
								}
							})
						}, 1200)
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						console.log(response.data.msg)
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('抢单失败，点支付继续抢单');
				})
			},
			getPayRes() {
				const that = this;
				that.getPayResInteval = setTimeout(() => {
					Toast.loading({
						message: '正在获取支付结果...' + that.count,
						forbidClick: true,
						duration: 0,
					});
					that.count = that.count + 1;
					let orderid = that.orderInfo.id;
					let params = new FormData()
					params.append('orderId', that.orderInfo.id);
					that.axios.post(config.requestUrl + '/front/order/queryOrderStatus', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (response.data.obj.orderStatus == 3 || response.data.obj
								.orderStatus == 5 ||
								response.data.obj.orderStatus == 2) {
								Toast.success('支付成功')
								setTimeout(() => {
									that.$router.replace({
										path: "/open",
										query: {
											orderId: that.orderInfo.id
										}
									})
								}, 1200)
								clearInterval(that.getPayResInteval)
							} else {
								that.getPayRes(that.getPayResInteval);
							}
						} else {
							Toast.fail(response.data.msg);
						}
					}, response => {
						Toast.fail('获取失败，请稍后重试');
					})

				}, 2000)

			},
			closePay() {
				this.payModel = false;
			},
			choosePayType(type) {
				this.payType = type
			},
			getGetG5PayRes() {
				const that = this;
				Dialog.confirm({
						title: '提示',
						message: '请确认微信支付是否已完成？',
						// confirmButtonText:'完成支付',
						// cancelButtonText:'取消支付'
					})
					.then(() => {
						that.getPayRes();
					})
					.catch(() => {
						console.log('关闭了')
					});
			}
		},
		mounted() {
			console.log(this.$route)
			document.addEventListener('visibilitychange', (e) => {
				console.log(document.visibilityState);
				let state = document.visibilityState
				if (state == 'hidden') {
					console.log('用户离开了');
				}
				if (state == 'visible') {
					// alert('用户回来了' + this.wxH5Pay);
					if (this.wxH5Pay) {
						this.wxH5Pay = 0;
						this.getGetG5PayRes();
						Toast.loading({
							message: '正在获取支付结果...' + this.count,
							forbidClick: true,
							duration: 0,
						});
					}
					//调用接口,获取订单状态
				}
			});

		},
		created() {
			this.buyModal = false;
			this.payModel = false;
			this.showLogin = false;
			this.isBuy = false;
		},
		activated() {
			console.log('activated')
			document.body.scrollIntoView()
			util.wxConfig();
			const id = this.$route.query.id;
			if (!id) {
				this.$router.push('/')
			}
			this.id = id;
			util.wxConfig();
			this.getDetail();
			this.queryContractAddress();
			const that = this;

		},
		beforeRouteLeave(to, from, next) {
			if (to.path == '/' || to.name == 'home') {
				if (this.buyModal) {
					this.buyModal = false;
					next(false)
				} else if (this.showVerify) {
					this.showVerify = false
					next(false)
				} else if (this.payModel) {
					this.payModel = false
					next(false)
				} else if (this.showLogin) {
					this.showLogin = false
					next(false)
				} else {
					clearTimeout(this.djInt);
					next();
				}
			} else {
				this.buyModal = false;
				this.showLogin = false;
				this.payModel = false;
				this.showVerify = false;
				this.$forceUpdate();
				console.log('厉害' + this.payModel)
				clearTimeout(this.djInt);
				next();
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 750px;
		overflow-x: hidden;
		background: #000000;
		padding-bottom: 160px !important;
	}

	.container img {
		max-width: 100% !important;
	}

	.container image {
		max-width: 100% !important;
	}

	.title-img {
		width: 750px;
		height: 48px;
		position: fixed;
		left: 0;
		top: 0;
		box-sizing: border-box;
		z-index: 1000;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 28px;
		text-align: center;
	}

	.back-icon {
		width: 46px;
		height: 46px;
		padding: 20px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto 0;
	}

	.detail-bg-img {
		width: 750px;
		height: 920px;
	}

	.chou-sec {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.time-sec {
		width: 302px;
		height: 50px;
		border-radius: 30px;
		/* opacity: 0.15; */
		margin-bottom: 29px;
	}

	.loudou-img {
		width: 33px;
		height: 33px;
		margin-right: 14px;
	}

	.chou-time {
		font-size: 24px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 33px;
	}

	.manghe-sec {
		width: 100%;
	}

	.manghe-bg-img {
		width: 410px;
		height: 191px;
	}

	.manghe-title {
		text-align: center;
		font-size: 40px;
		color: #FFFFFF;
		/* letter-spacing: 21px; */
		height: 68px;
		width: 373px;
		line-height: 68px;
		background: #6236FF;
		border-radius: 14px 14px 0px 0px;
		margin-right: 11px;
	}

	.wfsm {
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 28px;
		letter-spacing: 20px;
		position: absolute;
		top: 35px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
	}

	.good-bg-img {
		width: 126px !important;
		height: 108px;
		background: #AC95FF;
		border: 3px solid #000000;
		box-sizing: border-box;
		padding: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		border-radius: 0 !important;
	}

	.good-bg-img-hover {
		box-shadow: inset 0px 0px 10px 2px #000 !important;
	}

	.good-big-img-list {
		width: 410px;
		height: 355px;
		background: #6236FF;
	}

	.good-img1 {
		width: 57px;
		height: 57px;
		margin: 0 auto;
	}

	.good-big-img-item {
		padding: 16px;
	}

	.good-index {
		font-size: 23px;
		font-weight: bold;
		color: #000000;
		line-height: 24px;
		position: absolute;
		top: 3px;
		left: 2px;
	}

	.triangle-topleft {
		width: 0;
		height: 0;
		border-top: 38px solid #FFE206;
		border-right: 40px solid transparent;
		position: absolute;
		top: 0;
		left: 0;
	}

	.good-img-shiji {
		width: 76px;
		height: 76px;
		border: 4px solid #000000;
		box-sizing: border-box;
		border-radius: 50%;
		margin: 0 auto;
	}

	.manghe-qie {
		width: 60px;
		height: 60px;
		margin-left: 44px;
	}

	.mi-img {
		width: 410px;
		height: 144px;
	}

	.play-sm-sec {
		background: #FF8B70;
		overflow: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 10;
		width: 100%;
	}

	.pick-sec {
		padding: 0 28px;
		height: 115px;
		box-sizing: border-box;
	}

	.good-fg-sec {
		/* margin-top: 35px; */
	}

	.paidui-sec {}

	.head-img {
		width: 90px;
		height: 90px;
		/* box-sizing: border-box; */
		margin-right: 10px;
		border-radius: 50%;
	}

	.head-img-self {
		border: 4px solid #6236FF;
	}

	.mt35 {
		margin-top: 35px;
	}

	.daonile {
		width: 90px;
		height: 90px;
		background: #000000;
		font-size: 24px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 33px;
		letter-spacing: 2px;
		text-align: center;
		border-radius: 50%;
		padding: 12px;
		box-sizing: border-box;
	}

	.pick-btn {
		width: 265px !important;
		height: 70px;
		background: #FF5E38;
		border-radius: 39px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		padding: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.pick-btn2 {
		width: 265px;
		height: 70px;
		background: #FF5E38;
		border-radius: 39px;
		font-size: 28px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
	}

	.good-fg {
		width: 750px;
		height: 1138px;
	}

	.container-bot {
		background: #000000;
		padding: 0 20px;
	}

	.play-desc-sec {
		width: 710px;
		background: #6236FF;
		border-radius: 0px 0px 49px 49px;
		padding: 26px 0;
	}

	.play-desc {
		width: 617px;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 44px;
		margin-bottom: 7px;
	}

	.tips-img {
		width: 624px;
		height: 148px;
	}

	.tips-desc {
		font-size: 26px;
		color: #000000;
		line-height: 38px;
		position: absolute;
		top: 0;
		left: 0;
		width: 566px;
		height: 148px;
		text-align: center;
		right: 0;
		margin: 0 auto;
	}

	.share-img-btn {
		width: 408px !important;
		padding: 0;
	}

	.share-img {
		width: 408px;
		height: 90px;
		margin-top: 22px;
	}

	.goods-detail {
		width: 700px;
		background: #FFFFFF;
		border-radius: 30px;
		margin: 20px auto 0 auto;
	}

	.good-img-9 {
		width: 176px;
		height: 184px;
	}

	.good-img-mini-item {
		width: 45px;
		height: 45px;
		border-radius: 50%;
	}

	.model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
	}

	.jiemi-model {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 500px;
		height: 836px;
		margin: auto auto;
	}

	.card-bg {
		width: 500px;
		height: 638px;
	}

	.jiemi-mid {
		position: absolute;
		top: -45px;
		left: 0;
		width: 500px;
		/* height: 638px; */
	}

	.baoxiang-img {
		width: 164px;
		height: 145px;
	}

	.jiemi-img {
		width: 432px;
		height: 282px;
	}

	.for-mi {
		width: 410px;
		height: 144px;
		position: relative;
	}

	.mi-desc {
		position: absolute;
		top: 0;
		left: 0;
		width: 410px;
		height: 144px;
		padding: 31px 13px 14px 26px;
		box-sizing: border-box;
		font-size: 24px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 33px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.jiemi-sm {
		width: 336px;
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 40px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		margin-top: 62px;
		height: 220px;
	}

	.friend-share-btn {
		width: 423px !important;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		margin-top: 62px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 7px;
		text-align: center;
		padding: 0;
	}

	.close-img {
		width: 91px;
		height: 91px;
		margin-top: 204px;
	}

	/* 价格说明部分 */
	.price-sm {
		width: 650px;
		/* height: 475px; */
		font-size: 22px;
		color: #FFFFFF;
		line-height: 36px;
		margin: 34px auto 0 auto;
	}

	.price-circle {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: #FF8B70;
		margin-right: 6px;
	}


	.user-content-logo {
		width: 90px;
		height: 90px;
		display: block;
		overflow: hidden;
		border-radius: 100%;
		border: 4px solid #6236FF;
		box-sizing: border-box;
		margin-right: 10px;
	}

	/* 定义keyframe动画，命名为blink */
	@keyframes blink {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	/* 添加兼容性前缀 */
	@-webkit-keyframes blink {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}

	/* 定义blink类*/
	.blink {
		animation: blink 1s linear infinite;
		/* 其它浏览器兼容性前缀 */
		-webkit-animation: blink 1s linear infinite;
		-moz-animation: blink 1s linear infinite;
		-ms-animation: blink 1s linear infinite;
		-o-animation: blink 1s linear infinite;
	}

	.pick-btn-false {
		width: 265px !important;
		height: 70px;
		background: #F1F1F1;
		border-radius: 39px;
		font-size: 28px;
		font-weight: bold;
		color: #CACAD2;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		padding: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.weidao {
		width: 90px;
		height: 90px;
		background: #fff;
		border: 4px solid #000;
		color: #000;
		line-height: 33px;
		letter-spacing: 2px;
		text-align: center;
		border-radius: 50%;
		padding: 12px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.weidao-1 {
		font-size: 22px;
	}

	.weidao-2 {
		font-size: 14px;
	}


	.good-model {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto auto;
		width: 650px;
		height: 1088px;
		border-radius: 20px;
		border: 5px solid #000000;
		box-sizing: border-box;
	}

	.good-model-detail {
		width: 650px;
		height: 958px;
		border-radius: 20px;
		border: 5px solid #000000;
		box-sizing: border-box;
		margin-top: 130px;
		background: #FFFFFF;
	}

	.good-model-title {
		width: 641px;
		height: 130px;
		background: #FFFFFF;
		border-radius: 20px;
		border: 5px solid #000000;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 42px;
		letter-spacing: 3px;
		box-sizing: border-box;
		padding: 23px 41px;
		position: absolute;
		top: 25px;
		left: 6px;
		z-index: 1000;
		text-align: center;
	}

	.good-model-content {
		padding: 48px 32px 130px 32px;
	}

	.good-model-content img {
		width: 586px;
		vertical-align: middle;
	}


	.good-btn-sec {
		width: 645px;
		height: 90px;
		background: #FF8B70;
		border-radius: 20px;
		border: 5px solid #000000;
		font-size: 30px;
		font-weight: bold;
		color: #000000;
		line-height: 90px;
		letter-spacing: 3px;
		position: absolute;
		left: 2px;
		/* right:0; */
		bottom: -8px;
		/* margin: 0 auto; */
		box-sizing: border-box;
		text-align: center;
	}

	.xian {
		width: 1px;
		height: 58px;
		background: #000000;
		/* margin: 0 103px 0 121px; */
	}

	.close-btn {
		width: 49%;
	}

	.xinyonghu {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		/* background: rgba(172,149,255,0.7); */
	}

	.xinshou {
		width: 100%;
	}

	.x-tishi {
		width: 465px;
		height: 250px;
		position: fixed;
		top: 32px;
		right: 90px;
	}

	.x-shou {
		width: 200px;
		height: 200px;
		position: fixed;
		top: 532px;
		right: 214px;
	}

	.none-line {
		font-size: 30px;
	}

	.donghua {
		/* transform: translate(-100px,0px); */
	}

	.for-wanfa {
		width: 100%;
		padding-top: 38px;
		height: 578px;
	}

	.wanfa {
		width: 100%;
		height: 578px;
	}

	.wanfa-share {
		position: absolute;
		left: 225px;
		bottom: 41px;
		width: 300px !important;
		height: 80px;

		border-radius: 38px;
		padding: 0;
	}

	.wanfa-share img {
		width: 300px !important;
		height: 80px;
	}

	.blind_img1 {
		width: 116px;
		height: 116px;
		position: absolute;
		top: 102px;
		left: 197px;
		border-radius: 10px;
	}

	.blind_img2 {
		position: absolute;
		top: 102px;
		left: 330px;
	}

	.blind_img3 {
		position: absolute;
		top: 102px;
		left: 388px;
	}

	.blind_img4 {
		position: absolute;
		top: 102px;

		left: 444px;
	}

	.blind_img5 {
		position: absolute;
		top: 102px;

		left: 504px;
	}

	.blind_img6 {
		position: absolute;
		top: 175px;

		left: 330px;
	}

	.blind_img7 {
		position: absolute;
		top: 175px;

		left: 388px;
	}

	.blind_img8 {
		position: absolute;
		top: 175px;

		left: 444px;
	}

	.blind_img9 {
		position: absolute;
		top: 175px;

		left: 504px;
	}

	.model70 {
		margin-left: 170px;
	}

	.w64 {
		width: 64px;
	}

	.buy-one {
		width: 150px;
		height: 70px;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		background: #FF5E38;
		border-radius: 35px 0 0 35px;
	}

	.pre-buy {
		width: 180px;
		height: 70px;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		background: #FF5E38;
		border-radius: 35px;
	}

	.buy-more {
		width: 150px;
		height: 70px;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		background: #FF2222;
		border-radius: 0 35px 35px 0;
	}

	.base-info-wrap {
		background: #000000;
		padding: 80px 20px;
		box-sizing: border-box;
	}

	.other-info-wrap {
		background: #000000;
		padding: 0 20px 80px 20px;
		overflow: scroll;
	}

	.other-info-wrap>>>img {
		max-width: 100% !important;
		height: auto !important;
	}



	.base-info {
		padding: 33px 30px;
		background: #222222;
		border-radius: 30px;
		font-size: 28px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.base-info-t {
		width: 145px;
	}

	.base-info-c {
		width: 505px;
		word-wrap: break-word;
		word-break: break-all;
		text-align: right;
		line-height: 44px;
	}

	.mt10 {
		margin-top: 10px;
	}


	/* 选定商品弹窗 */
	.pick-good-model {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 750px;
		background: #FFFFFF;
		border-radius: 66px 66px 0px 0px;
		padding: 0 40px;
		box-sizing: border-box;
		z-index: 1000;
		padding-bottom: 80px;
	}

	.tishi-address {
		font-size: 28px;
		color: #FF8B70;
		line-height: 28px;
		text-align: center;
		margin-top: 40px;
		height: 28px;
	}

	.pick-title {
		font-size: 32px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		margin-top: 58px;
		margin-bottom: 30px;
	}

	.pick-item {
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px -1px 0px 0px #EFEFEF;
	}

	.pick-item-left {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
	}

	.pick-item-right {
		font-size: 28px;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
		margin-left: 10px;
		width: 570px;
	}

	.pick-confirm-btn {
		width: 500px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin-top: 100px;
		margin: 100px auto 0 auto;
	}

	.pick-confirm-btn-false {
		width: 500px;
		height: 70px;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin-top: 100px;
		margin: 100px auto 0 auto;
		background: #F1F1F1;
		color: #CACAD2;
	}


	.pick-item-tishi {
		padding-top: 38px;
		box-shadow: 0px -1px 0px 0px #EFEFEF;
	}

	.pay-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 650px;
		height: 630px;
		margin: auto;
		background: #FFFFFF;
		border-radius: 22px;
		z-index: 1001;
		padding: 150px 30px 50px;
		box-sizing: border-box;
	}

	.pay-type-wrap {}

	.pay-type {}

	.pay-img {
		width: 140px;
		height: 140px;
	}

	.pay-text {
		margin-top: 20px;
		font-size: 28px;
		color: #000000;
	}

	.pay-radio {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #DDDDDD;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.pay-radio-sel {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #3FCF77;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.btn-pay {
		width: 590px;
		height: 100px;
		background: #FF5E38;
		border-radius: 60px;
		line-height: 100px;
		font-size: 38px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		margin-top: 88px;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #FF5E38;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.box-detailimg {
		width: 410px;
		height: 355px;
	}


	.buy-confirm-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1001;
		background: #FFFFFF;
		padding-bottom: 18px;
	}

	.bc-top {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: relative;
	}

	.bc-ti {
		width: 750px;
		text-align: center;
	}

	.close-bc {
		position: absolute;
		top: 30px;
		right: 34px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}

	.tips-wrap {
		width: 750px;
		background: rgba(247, 181, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		padding: 20px 40px;
		box-sizing: border-box;
	}

	.icon-tips {
		width: 21px;
		height: 24px;
		margin-right: 9px;
	}

	.tips {
		font-size: 24px;
		font-weight: 600;
		color: #FA6400;
	}

	.addr-wrap {
		width: 388px;
		height: 80px;
		margin: 60px auto 89px;
		background: rgba(201, 201, 201, 0.1);
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	.addr-input {
		width: 318px;
		height: 80px;
		background: rgba(201, 201, 201, 0.1);
		border: none;
		font-size: 28px;
		font-weight: 400;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.ques-img {
		width: 32px;
		height: 32px;
		margin-left: 21px;
	}

	.num-con {
		margin: 60px auto 0
	}

	.reduce {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.num {
		width: 130px;
		height: 80px;
		background: rgba(0, 0, 0, 0.1);
		text-align: center;
		border: none;
	}

	.add {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}

	.confirm-btn {
		width: 590px;
		height: 80px;
		margin: auto;
		background: linear-gradient(180deg, #FF8B70 0%, #FF5E38 100%);
		border-radius: 40px;
		text-align: center;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 80px;
		margin-top: 80px
	}
</style>
<template>
	<div class="container">
		<div class="flex-row align-center justify-end">
			<div class="bill" @click.stop="navBalanceList()">账单</div>
		</div>
		<div class="balnace flex-row align-center">
			<div class="bal-ti">余额</div>
			<div class="bal-num">{{balance}}元</div>
		</div>
		<div class="recharge-wrap flex-row align-center  flex-wrap">
			<block v-for="(item,index) in typeArr" v-bind:key="index">
				<div class="recharge-item" :class="type == index?'recharge-item-sel':''" @click="changeMoney(index)">
					{{item.rechargeAmount}}元
				</div>
			</block>
		</div>
		<div class="foot-wrap">
			<!-- <div class="tips">提示: 充值余额将直接转入寄售平台账户，请理性充值！</div> -->
			<!-- <div class="tips">提示: 充值已关闭 第四期见</div> -->
			<div class="tips2">提示：请提前安装云闪付APP</div>
			<div class="rec-bth" @click.stop="recharge()">充值</div>
			<!-- <div class="more-btn" @click.stop="returnPrice()">退款</div> -->
			<div class="more-btn" @click.stop="returnPrice()">
				退款{{isNumCanRefundAmount?'￥'+canRefundAmount:'('+canRefundAmount+')'}}</div>
			<div class="more-btn" @click.stop="doInnerTransfer()">
				转收入{{isNumCanRefundAmount?'￥'+surplusToDoAmount:'('+surplusToDoAmount+')'}}</div>
		</div>
		<div class="pay-wrap" v-if="payModel">
			<div class="pay-type-wrap flex-row align-center space-around">
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(1)">
					<img src="../assets/wx.png" class="pay-img" />
					<div class="pay-text">微信</div>
					<div class="pay-radio" :class="payType == 1?'pay-radio-sel':''"></div>
				</div> -->
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(2)">
					<img src="../assets/zfb.png" class="pay-img" />
					<div class="pay-text">支付宝</div>
					<div class="pay-radio" :class="payType == 2?'pay-radio-sel':''"></div>
				</div> -->
				<!-- 	<div class="pay-type flex-column align-center" @click="choosePayType(4)">
					<img src="../assets/ysf-big.png" class="pay-img" />
					<div class="pay-text">银联云闪付</div>
					<div class="pay-radio" :class="payType == 4?'pay-radio-sel':''"></div>
				</div> -->
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(6)">
					<img src="../assets/zfb.png" class="pay-img" />
					<div class="pay-text">支付宝</div>
					<div class="pay-radio" :class="payType == 6?'pay-radio-sel':''"></div>
				</div>-->
				<!-- <div class="pay-type flex-column align-center" @click="choosePayType(7)">
					<img src="../assets/yl.png" class="pay-img" />
					<div class="pay-text">银联快捷</div>
					<div class="pay-radio" :class="payType == 7?'pay-radio-sel':''"></div>
				</div> -->
				<div class="pay-type flex-column align-center" @click="choosePayType(8)">
					<img src="../assets/ysf-big.png" class="pay-img" />
					<div class="pay-text">银联云闪付</div>
					<div class="pay-radio" :class="payType == 8?'pay-radio-sel':''"></div>
				</div>
			</div>
			<div class="btn-pay" @click.stop="confirmPay()">支付</div>

		</div>
		<div class="model" v-if="payModel" @click.stop="closePay">
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	export default {
		data() {
			return {
				balance: 0,
				typeArr: [],
				type: 0,
				payModel: false,
				payType: 8,
				wxH5Pay: 0, //是否开始了其他浏览器h5支付
				surplusToDoAmount: '',
				canRefundAmount: '',
				isNumCanRefundAmount: false,
				isNumSurplusToDoAmount: false
			}
		},
		methods: {
			navBalanceList() {
				this.$router.push('/balancelist')
			},
			changeMoney(type) {
				this.type = type;
			},
			recharge() {
				this.payModel = true;
			},
			confirmPay() {
				// Toast('充值未开放 请直接下单购买');
				const that = this
				Toast.loading({
					message: '正在获取支付参数...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('currencyId', 1)
				params.append('rechargeConfigId', this.typeArr[this.type].id)
				// params.append('rechargeType', this.payType)
				params.append('rechargeType', this.payType == 4 ? 3 : 4)
				this.axios.post(config.requestUrl + '/front/financial/doRechargeOne', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.msg = this.msg + "提交订单:成功" + "\n";
					if (response.data.code == 0) {
						this.orderInfo = response.data.obj;
						if (this.payType == 1) {
							this.getPayInfo()
						} else if (this.payType == 2) {
							Toast.clear();
							this.$router.push({
								path: "/zfb",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									type: 2
								}
							});
						} else if (this.payType == 4) {
							this.$router.push({
								path: "/scanpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 6) {
							this.$router.push({
								path: "/sdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 7) {
							this.$router.push({
								path: "/scansdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						} else if (this.payType == 8) {
							this.$router.push({
								path: "/codesdpay",
								query: {
									orderId: this.orderInfo.id,
									token: this.$store.state.user.token,
									money: this.money,
									type: 2
								}
							});
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			getPayInfo() {
				const that = this;
				let params = new FormData()
				params.append('orderId', this.orderInfo.id);
				params.append('payType', util.isWx() ? 1 : 2)
				params.append('rechargeConfigId', this.typeArr[this.type].id);
				params.append('rechargeType', 1);
				this.axios.post(config.requestUrl + '/front/financial/doRechargeTwo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.msg = this.msg + "getPayInfo:结果成功" + "\n";
						Toast.clear();
						console.log(response)
						if (util.isWx()) {
							let payData = response.data.obj.jsapiResult;
							wx.ready(function() {
								wx.chooseWXPay({
									timestamp: payData.timeStamp,
									nonceStr: payData.nonceStr,
									package: payData.package,
									signType: payData.signType,
									paySign: payData.sign, // 支付签名
									success: function(res) {
										// 支付成功后的回调函数
										Toast.loading({
											message: '正在获取支付结果...',
											forbidClick: true,
											duration: 0,
										});
										that.getPayRes(0);
									},
									fail() {
										Toast.fail('取消支付')
									}
								});
							});
						} else {
							console.log(response.data.obj.h5PayResult)
							this.wxH5Pay = 1;
							this.payModel = false;
							window.location.href = response.data.obj.h5PayResult.mweb_url
						}
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			getPayRes(redirect) {
				const that = this;
				that.getPayResInteval = setTimeout(() => {
					Toast.loading({
						message: '正在获取支付结果...' + that.count,
						forbidClick: true,
						duration: 0,
					});
					that.count = that.count + 1;
					let orderid = that.orderInfo.id;
					let params = new FormData()
					params.append('id', that.orderInfo.id);
					params.append('type', 1);
					that.axios.post(config.requestUrl + '/front/financial/detailInfo', params, {
						headers: {
							'Content-Type': 'multipart/form-data',
							'token': that.$store.state.user.token
						}
					}).then(response => {
						if (response.data.code == 0) {
							if (response.data.obj.status == 2) {
								Toast.success('支付成功')
								this.getWalletByCurrencyId();
								clearInterval(that.getPayResInteval)
								if (redirect) {
									this.$router.replace('/balance')
								}
							} else {
								that.getPayRes(redirect);
							}
						} else {
							Toast.fail(response.data.msg);
						}
					}, response => {
						Toast.fail('获取失败，请稍后重试');
					})

				}, 2000)

			},
			closePay() {
				this.payModel = false;
			},
			choosePayType(type) {
				this.payType = type
			},
			getGetG5PayRes() {
				const that = this;
				Dialog.confirm({
						title: '提示',
						message: '请确认微信支付是否已完成？',
						// confirmButtonText:'完成支付',
						// cancelButtonText:'取消支付'
					})
					.then(() => {
						that.getPayRes(0);
					})
					.catch(() => {
						console.log('关闭了')
					});
			},
			getRechargeConfigList() {
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/financial/getRechargeConfigList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						console.log(response)
						this.typeArr = response.data.obj
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			getWalletByCurrencyId(cb) {
				const that = this;
				let params = new FormData()
				params.append('currencyId', 1)
				that.axios.post(config.requestUrl + '/front/financial/getWalletByCurrencyId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.balance = response.data.obj.using;
						this.canRefundAmount = response.data.obj.canRefundAmount;
						that.isNumCanRefundAmount = util.isRealNum(response.data.obj.canRefundAmount)
						this.surplusToDoAmount = response.data.obj.surplusToDoAmount;
						that.isNumSurplusToDoAmount = util.isRealNum(response.data.obj.surplusToDoAmount)
						if (cb) cb();
						console.log(response)
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			returnPrice() {
				const that = this;
				if (!util.isRealNum(that.canRefundAmount)) return;
				Dialog.confirm({
						allowHtml: true,
						title: '申请退款',
						message: '余额退款<span style="font-weight:bolder">三个工作日</span>内到账，原路退回付款账户，是否确认退款？',
					})
					.then(() => {
						Toast.loading({
							message: '提交中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						params.append('currencyId', 1);
						that.axios.post(config.requestUrl + '/front/financial/balanceRefund', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.clear();
								this.$router.replace({
									path: '/refundsuccess',
									query: {
										money: response.data.obj
									}
								})
								// Toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			doInnerTransfer() {
				const that = this;
				that.getWalletByCurrencyId(() => {
					if (!util.isRealNum(that.surplusToDoAmount)) return;
					Dialog.confirm({
							allowHtml: true,
							title: '转收入',
							message: '转收入后可提现，是否确认？',
						}).then(() => {
							Toast.loading({
								message: '提交中...',
								forbidClick: true,
								duration: 0,
							});
							let params = new FormData()
							params.append('currencyId', 1);
							params.append('transferAmount', that.surplusToDoAmount);
							params.append('transferType', 2);

							that.axios.post(config.requestUrl + '/front/transfer/doInnerTransfer', params, {
								headers: {
									'Content-Type': 'multipart/form-data',
									'token': this.$store.state.user.token
								}
							}).then(response => {
								if (response.data.code == 0) {
									that.getWalletByCurrencyId();
									Toast.clear();
									Toast.success('划转成功')
								} else {
									let msg = response.data.msg;
									const str = "未获取到用户信息";
									const str2 = "登录已失效"
									if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
										localStorage.removeItem("user");
										that.$store.commit("clearUser");
										that.$store.commit("clearCode");
										setTimeout(() => {
											this.$router.push('/')
										}, 1200)
									}
									Toast(response.data.msg);
								}
								this.loading = 0;
							}, response => {
								this.loading = 0;
								Toast('获取失败，请稍后重试');
							})
						})
						.catch(() => {
							console.log('取消退出登录')
							// on cancel
						});
				})
			}
		},
		mounted() {
			this.getWalletByCurrencyId();
			this.getRechargeConfigList();
			if (this.$route.query.orderId) {
				this.orderInfo = {
					id: this.$route.query.orderId
				}
				this.getPayRes(1);
			}
			if (this.$route.query.token) {
				this.$store.commit('setToken', this.$route.query.token)
				this.$store.commit('setToken', this.$route.query.token);
				let user = {}
				if (localStorage.getItem("user")) {
					user = JSON.parse(localStorage.getItem("user"))
					user.token = this.$route.query.token;
				} else {
					user = {
						token: this.$route.query.token
					}
				}
				localStorage.setItem('user', JSON.stringify(user))
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		min-height: 100%;
		background: #FFFFFF;
		padding: 0 70px 111px 70px;
		box-sizing: border-box;
	}

	.bill {
		width: 105px;
		height: 49px;
		background: rgba(98, 54, 255, 0.07);
		border-radius: 25px;
		margin-top: 20px;
		text-align: center;
		font-size: 26px;
		font-weight: 600;
		color: #000000;
		line-height: 49px;
		/* margin-right: 30px; */
	}

	.bal-ti {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
		letter-spacing: 1px;
		margin-right: 60px;
		margin-left: 30px;
	}

	.bal-num {
		font-size: 28px;
		font-weight: 400;
		color: #999999;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.recharge-wrap {
		margin-top: 70px;
	}

	.recharge-item {
		width: 180px;
		height: 100px;
		border-radius: 60px;
		line-height: 100px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		margin: 10px;
	}

	.recharge-item-sel {
		background: #6236FF;
		color: #FFFFFF;
	}

	.foot-wrap {
		width: 590px;
		height: 145px;
		position: fixed;
		bottom: 80px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}

	.tips {
		font-size: 18px;
		font-weight: 400;
		color: #999999;
		line-height: 25px;
		margin-bottom: 20px;
	}

	.tips2 {
		font-size: 20px;
		font-weight: 400;
		color: #999999;
		line-height: 25px;
		margin-bottom: 20px;
	}

	.rec-bth {
		width: 590px;
		height: 100px;
		line-height: 100px;
		background: #FF5E38;
		border-radius: 60px;
		text-align: center;
		font-size: 38px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.pay-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 650px;
		height: 630px;
		margin: auto;
		background: #FFFFFF;
		border-radius: 22px;
		z-index: 1001;
		padding: 150px 30px 50px;
		box-sizing: border-box;
	}

	.pay-type-wrap {}

	.pay-type {}

	.pay-img {
		width: 140px;
		height: 140px;
	}

	.pay-text {
		margin-top: 20px;
		font-size: 28px;
		color: #000000;
	}

	.pay-radio {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #DDDDDD;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.pay-radio-sel {
		width: 20px;
		height: 20px;
		margin-top: 10px;
		background: #3FCF77;
		border-radius: 100%;
		border: 6px solid #DDDDDD;
	}

	.foot-wrap {
		width: 590px;
		height: 325px;
		position: fixed;
		bottom: 80px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}

	.btn-pay {
		width: 590px;
		height: 100px;
		background: #FF5E38;
		border-radius: 60px;
		line-height: 100px;
		font-size: 38px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		margin-top: 88px;
	}

	.more-btn {
		width: 590px;
		height: 100px;
		border: 1px solid #6236FF;
		border-radius: 60px;
		font-size: 38px;
		color: #000000;
		line-height: 100px;
		text-align: center;
		margin-top: 25px;
		box-sizing: border-box;
	}
</style>

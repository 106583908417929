<template>
	<div class="container">
		<div class="info flex-column">
			<img src="../assets/info-top.png" class="info-top-img">
			<div class="info-center flex-column">
				<div class="title">区块链查证信息概览</div>
				<div class="flex-row align-center space-between">
					<div class="stitle">产品数据</div>
					<img src="../assets/arrow-top.png" class="arrow-top">
				</div>
			</div>
			<img src="../assets/info-bottom.png" class="info-top-bottom">
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	export default {
		data() {
			return {}
		},
		methods: {

		},
		mounted(){
			util.wxConfig();
		}
	}
</script>

<style>
	.info {
		width: 590px;
		margin: 83px auto;
	}

	.info-top-img {
		width: 590px;
		height: 50px;
	}

	.info-top-bottom {
		width: 590px;
		height: 50px;
	}

	.info-center {
		background-image: url(../assets/info-center.png);
		background-size: contain;
		background-repeat: repeat-y;
		width: 590px;
	}

	.title {
		font-size: 30px;
		font-weight: 600;
		color: #77ABF5;
		line-height: 42px;
		letter-spacing: 2px;
		text-align: center;
		margin-bottom: 20px;
	}

	.stitle {
		font-size: 28px;
		font-weight: 400;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.arrow-top {
		width: 22px;
		height: 22px;
	}
</style>

import {
	Vue
} from "vue";
import config from './config.js';
import wx from 'weixin-js-sdk'
import axios from 'axios';
import getSign from '../plugins/tools.js';
import imgUrl from '@/assets/share_img.jpg';

function isWx() {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	var ua = window.navigator.userAgent.toLowerCase();
	// alert(JSON.stringify(ua))
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}
}

function formatDate(val) {
	var date = new Date(val);
	let year = date.getFullYear();
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return `${year}.${month}.${day} ${hours}:${minutes}`;
}

function wxConfig() {
	if (isWx()) {
		let params = new FormData()
		axios.post(config.requestUrl + '/front/getAccessTokenAndJSTicket', params, {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}).then(response => {
			if (response.data.code == 0) {
				let tokenArr = JSON.parse(response.data.obj);
				let signArr = getSign(tokenArr['ticket']);
				let link = config.host.substr(0, config.host.length - 1);
				wx.config({
					debug: false,
					appId: signArr.appId,
					timestamp: signArr.timestamp,
					nonceStr: signArr.noncestr,
					signature: signArr.signature,
					jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData']
				})
				wx.ready(function() { //需在用户可能点击分享按钮前就先调用
					wx.updateAppMessageShareData({
						title: '万物数字藏品', // 分享标题
						desc: '', // 分享描述
						link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: link + imgUrl, // 分享图标
						success: function() {
							// 设置成功
						}
					})

					wx.updateTimelineShareData({
						title: '万物数字藏品', // 分享标题
						link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
						imgUrl: link + imgUrl, // 分享图标
						success: function() {
							// 设置成功
						}
					})
				});

			} else {
				console.log(222)
			}
		}, response => {
			console.log(111)
		})
	}
}

function randomString() {
	let len = rand(3, 6);
	var $chars = 'ABCDEFGHIJKMNPOQRSTWXYZabcdefghijkmnoprstwxyz0123456789'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
	var maxPos = $chars.length;
	var pwd = '';
	for (let i = 0; i < len; i++) {
		pwd += $chars.charAt(Math.floor(Math.random() * maxPos));

	}
	return pwd;
}

function rand(min, max) {
	return parseInt(Math.random() * (max - min + 1) + min, 10);
}

function formatTime(val) {
	var date = new Date(val);
	let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)
		.toString())
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return month + '月' + day + '日 ' + hours + ':' + minutes;
}

function formatTime2(val) {
	var date = new Date(val);
	let month = (date.getMonth() + 1)
	let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate().toString())
	let hours = date.getHours() > 9 ? date.getHours() : ('0' + date.getHours().toString())
	let minutes = date.getMinutes() > 9 ? date.getMinutes() : ('0' + date.getMinutes().toString())
	return month + '月' + day + '日 ' + hours + ':' + minutes;
}

function getZqShowTime() {
	var date = new Date();
	let hours = date.getHours()
	let minutes = date.getMinutes()
	console.log(hours+'|'+minutes)
	if (hours > 16 || (hours == 16 && minutes >= 30)) {
		return 1;
	} else {
		return 0;
	}
}

function compareTime(date) {
	let now = new Date();
	let cDate = new Date(date);
	if (cDate.getTime() > now.getTime()) {
		return 0;
	}
	return 1;
}

function getPauseStatus(date, m) {
	let now = new Date();
	let cDate = new Date(date);
	if (cDate.getTime() + m * 60 * 1000 >= now.getTime()) {
		return 0;
	}
	return 1;
}

function isRealNum(val) {
	// isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，    
	if (val === "" || val == null) {
		return false;
	}
	if (!isNaN(val)) {
		//对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,   //所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
		return true;
	} else {
		return false;
	}
}

 
function formatStr(str) {
	return str.substring(0,1) + new Array(str.length).join('*')
}
 
function accMul(arg1, arg2) {
	var m = 0,
		s1 = arg1.toString(),
		s2 = arg2.toString();
	try {
		m += s1.split(".")[1].length
	} catch (e) {
		console.log(e)
	}
	try {
		m += s2.split(".")[1].length
	} catch (e) {
		console.log(e)
	}
	return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

export default {
	isWx,
	wxConfig,
	randomString,
	formatTime,
	compareTime,
	formatDate,
	formatTime2,
	isRealNum,
	getPauseStatus,
	getZqShowTime,
	formatStr,
	accMul
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Member from '../views/Member.vue'
import Detail from '../views/Detail.vue'
import Open from '../views/Open.vue'
import Info from '../views/Info.vue'
import Share from '../views/Share.vue'
import Deal from '../views/Deal.vue'
import Certify from '../views/Certify.vue'
import Webview from '../views/Webview.vue'
import Zfb from '../views/Zfb.vue'
import Balance from '../views/Balance.vue'
import Compound from '../views/Compound.vue'
import Collect from '../views/Collect.vue'
import Gift from '../views/Gift.vue'
import collectList from '../views/CollectList.vue'
import scanpay from '../views/scanpay.vue'
import refundsuccess from '../views/refundsuccess.vue'
import balancelist from '../views/balancelist.vue'
import sdpay from '../views/sdpay.vue';
import scansdpay from '../views/scansdpay.vue';
import codesdpay from '../views/codesdpay.vue';
import download from '../views/download.vue';
import test from '../views/Test.vue';
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: 'home',
		component: Home,
		meta: {
			keepAlive: true, //此组件不需要被缓存
			name: '万物数字藏品'
		}
	},
	{
		path: '/member',
		name: 'member',
		component: Member,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/detail',
		name: 'detail',
		component: Detail,
		meta: {
			keepAlive: true //此组件不需要被缓存
		}
	},
	{
		path: '/open',
		name: 'open',
		component: Open,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/info',
		name: 'info',
		component: Info,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/share',
		name: 'share',
		component: Share,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/deal',
		name: 'deal',
		component: Deal,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/certify',
		name: 'certify',
		component: Certify,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/webview',
		name: 'webview',
		component: Webview,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/zfb',
		name: 'zfb',
		component: Zfb,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/balance',
		name: 'balance',
		component: Balance,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/compound',
		name: 'compound',
		component: Compound,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/collect',
		name: 'collect',
		component: Collect,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/gift',
		name: 'gift',
		component: Gift,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/collectlist',
		name: 'collectlist',
		component: collectList,
		meta: {
			keepAlive: true
		}
	},
	{
		path: '/scanpay',
		name: 'scanpay',
		component: scanpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/refundsuccess',
		name: 'refundsuccess',
		component: refundsuccess,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/balancelist',
		name: 'balancelist',
		component: balancelist,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/sdpay',
		name: 'sdpay',
		component: sdpay,
		meta: {
			keepAlive: false
		}
	}, {
		path: '/scansdpay',
		name: 'scansdpay',
		component: scansdpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/codesdpay',
		name: 'codesdpay',
		component: codesdpay,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/download',
		name: 'download',
		component: download,
		meta: {
			keepAlive: false
		}
	},
	{
		path: '/test',
		name: 'test',
		component: test,
		meta: {
			keepAlive: false
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
<template>
	<div class="container" ref="getheight">
		<Share v-show="showCard" :card="card" />
		<PublicWrap :msg="msg" v-show="showPublic" v-on:close-public="closePublic()" />
		<div class="flex-column justify-center top">
			<img src='../assets/wode.png' class="mine-title-img" />
		</div>
		<div class="flex-row align-center loginout-wrap" @click="loginout()">
			<img src="../assets/loginout2.png" class="loginout-img" />
			<div class="loginout-text">退出登录</div>
		</div>
		<div class="relative">
			<img src='../assets/mine_bg.png' class="mine-bg-img" />
			<div class="mini-top-sec flex-column align-center ">
				<div class="flex-column align-center">
					<img v-if="user.pic" :src="user.pic" class="user-content-logo">
					<img v-else src="../assets/good1.png" class="user-content-logo">
					<div class="user-content-name ellipsis">{{user.name ? user.name:user.randName}}</div>
				</div>
				<img src="../assets/good1.png" v-show="false">
				<div class="flex-row align-center">
					<div class="cp-title">我的数字藏品</div>
					<div class="cp-num">{{productSize}}</div>
				</div>
				<div class="flex-row align-center addr-con">
					<img src="../assets/icon-addr.png" class="icon-addr">
					<div class="addr-title">区块链地址</div>
					<div class="addr-desc" :data-clipboard-text="user.address" @click="copy">{{user.address}}<img
							src="../assets/copy.png" class="copy"></div>
				</div>
			</div>
		</div>
		<div class="relative mini-top-sec-bot">
			<img src='../assets/mine_bg2.png' class="mine-bg-img2" />
			<div class="flex-row align-center mine-bg-item-sec1 justify-center">
				<div class="flex-column align-center justify-center item1" @click="openWallet()">
					<div class="relative">
						<img src='../assets/wallet.png' class="item-icon1" />
					</div>
					<div>余额</div>
				</div>
				<div class="line"></div>
				<div class="flex-column align-center justify-center item1" @click="openPublic()">
					<div class="relative">
						<img src='../assets/member-icon2.png' class="item-icon1" />
					</div>
					<div>客服</div>
				</div>
			</div>
		</div>
		<div class="compound-wrap flex-column justify-center align-center" @click.stop="navCompound()">
			<img src="../assets/compound.png" class="compound" />
			<div class="compound-text">合成</div>
		</div>
		<!-- 	<div class="cp-wrap flex-row align-center flex-wrap space-between">
			<block v-for="(item,index) in goodsList" v-bind:key="index">
				<img @click.stop="openCard(index)" v-if="item.isOpen == '是'" :src="item.productPic2" :data-index="index"
					:data-src="item.productPic2" :v-real-img="item.productPic2" class="cp-img">
				<img @click.stop="openCard(index)" v-else src="../assets/manghe.png" :data-index="index"
					data-src="../assets/manghe.png" mode="aspectFill" class="cp-img2">
			</block>
		</div> -->
		<div class="support-con flex-row align-center justify-center">
			<img src="../assets/icon-addr-black.png">
			<div>META链提供技术支持</div>
		</div>
		<div class="tab-footer-sec flex-row align-center">
			<div class="flex-column align-center tab-footer-btn" @click="navHome()">
				<img src='../assets/box.png' class="tab-no-active-img" />
				<div>抽盒</div>
			</div>
			<div class="flex-column align-center tab-footer-btn " @click.stop="navCollect()">
				<img src='../assets/collect.png' class="tab-no-active-img" />
				<div>藏品</div>
			</div>
			<div class="flex-column align-center tab-footer-btn relative">
				<img src='../assets/mine_select_icon.png' class="tab-active-img" />
				<div class="tab-footer-btn-title">我的</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import Clipboard from 'clipboard';
	import PublicWrap from '@/components/Public.vue';
	import util from '../config/util.js';
	export default {
		name: 'member',
		data() {
			return {
				tipModal: false,
				user: {},
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: 0,
				windowHeight: 0,
				offsetHeight: 0,
				productSize: 0,
				card: {},
				showCard: 0,
				showPublic: 0,
				msg: '长按二维码关注公众号 获取服务'
			}
		},
		components: {
			PublicWrap
		},
		computed: {
			goodsList() {
				return this.$store.state.goodsList
			},
		},
		mounted() {

			// this.getGoods();
			util.wxConfig();
			// window.addEventListener('scroll', this.onScroll, false);
			this.windowHeight = window.innerHeight; //窗口高度
			this.offsetHeight = this.$refs.getheight.offsetHeight;
			if (!util.isWx()) {
				this.msg = '请关注公众号获取服务'
			}
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.getMember();
			});
			// if (from.name == 'share') {
			// 	next();
			// } else {
			// 	console.log('123456')
			// 	next(vm => {
			// 		vm.$store.commit('setGoodsList', [])

			// 		console.log(vm.$store.state.goodsList)
			// 		vm.pageNum = 1;
			// 		vm.more = true;
			// 		vm.getGoods();
			// 		vm.getMember();
			// 	});
			// }
		},
		methods: {
			openCard(index) {
				this.card = this.list[index];
				this.card.index = index;
				this.$router.push({
					path: "/share",
					query: this.card
				});
			},
			navHome() {
				this.$router.push('/')
			},
			navCollect() {
				this.$router.replace('/collect')
			},
			showTips() {
				this.tipModal = true;
			},
			closeTips() {
				this.tipModal = false;
			},
			openPublic() {
				this.showPublic = true;
				this.$forceUpdate()
			},
			closePublic() {
				this.showPublic = false;
				this.$forceUpdate()
			},
			getMember() {
				const that =this
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/user/baseInfo', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					this.getBox = 0;
					if (response.data.code == 0) {
						this.user = response.data.obj.userInfo;
						console.log(this.user)
						this.user.randName = this.$store.state.user.randName;
						if (!this.$store.state.user.ranName) {
							let user = {
								token: this.$store.state.user.token,
								randName: util.randomString(),
								userPic: this.user.pic,
								nickName: this.user.name
							}
							console.log(user)
							this.$store.commit('setUser', user)
							localStorage.setItem('user', JSON.stringify(user))
						}

						this.productSize = response.data.obj.productSize
						Toast.clear()
					} else {
						Toast.fail(response.data.msg);
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}

					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			getGoods() {
				if (!this.more) {
					Toast.fail('没有更多了');
					return;
				}
				if (this.loading) return;
				this.loading = 1;
				let params = new FormData()
				params.append('pageNum', this.pageNum);
				params.append('pageSize', this.pageSize);
				this.axios.post(config.requestUrl + '/front/user/getProductListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let list = this.$store.state.goodsList;
						let newList = response.data.obj.list;
						for (let i in newList) {
							let arr = newList[i].productPic.split('/');
							let str = arr.pop();
							newList[i].productPic2 = config.imgUrl + str;
						}
						if (response.data.obj.isLastPage) this.more = false;
						list = list.concat(newList);
						this.pageNum = this.pageNum + 1;
						this.list = list;
						this.$store.commit('setGoodsList', list)
						this.offsetHeight = this.$refs.getheight.offsetHeight
						Toast.clear()
						this.$forceUpdate();
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast.fail(response.data.msg);
					}
					this.loading = 0;
				}, response => {
					this.loading = 0;
					Toast.fail('获取失败，请稍后重试');
				})
			},
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //页面滚动高度

				if (this.offsetHeight < scroll + this.windowHeight + 150) {
					if (this.more && !this.loading) {
						this.getGoods();
					}
				}
			},
			copy() {
				var clipboard = new Clipboard('.addr-desc')
				clipboard.on('success', e => {
					//  释放内存
					Toast.success('复制成功');
				})
				clipboard.on('error', e => {
					// 不支持复制
					// 释放内存
					Toast.success('复制失败');
				})
			},
			loginout() {
				const that = this;
				Dialog.confirm({
						title: '退出登录',
						message: '确认要退出登录？',
					})
					.then(() => {
						console.log('退出登录')
						Toast.loading({
							message: '退出中...',
							forbidClick: true,
							duration: 0,
						});
						let params = new FormData()
						that.axios.post(config.requestUrl + '/front/logout', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								localStorage.removeItem("user");
								setTimeout(() => {
									this.$router.push('/')
								}, 1200)
								Toast.success('退出成功')
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								Toast.fail(response.data.msg);
							}
							this.loading = 0;
						}, response => {
							this.loading = 0;
							Toast.fail('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消退出登录')
						// on cancel
					});
			},
			openWallet() {
				this.$router.push('balance')
			},
			navCompound() {
				this.$router.push('/compound')
			}
		}
	}
</script>

<style scoped="scoped">
	html {
		background: #F8F8F8 !important;
	}

	body {
		background: #F8F8F8 !important;
	}

	.container {
		min-height: 100%;
		position: relative;
		padding-bottom: 228px;
		box-sizing: border-box;
	}

	.top {
		z-index: 100;
		position: fixed;
		top: 10px;
		left: 0
	}

	.mine-title-img {
		width: 169px;
		height: 44px;
		margin-left: 30px;
	}

	.tishi-num {
		position: absolute;
		top: -15px;
		left: 100px;
		width: 45px;
		height: 25px;
		background: #FF0000;
		border-radius: 16px;
		border: 4px solid #000000;
		font-size: 24px;
		color: #000000;
		line-height: 25px;
		letter-spacing: 1px;
		text-align: center;
		/* -webkit-background-clip: text; */
		/* -webkit-text-fill-color: transparent; */
	}

	.line {
		width: 2px;
		height: 154px;
		border: 2px dashed #000;
		box-sizing: border-box;
		margin: 0 126px 0 116px
	}

	.mine-bg-img {
		width: 750px;
		height: 554px;
	}

	.user-content-logo {
		width: 134px;
		height: 134px;
		display: block;
		overflow: hidden;
		border-radius: 100%;
		border: 8px solid #fff;
		margin-top: 100px;
	}

	.user-content-name {
		font-size: 30px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 42px;
		letter-spacing: 2px;
		margin-top: 15px;
		margin-bottom: 10px;
	}

	.mini-top-sec {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}

	.mini-top-sec-bot {
		width: 729px;
		height: 334px;
		/* background: #6236FF; */
		border-radius: 20px;
		font-size: 30px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 42px;
		letter-spacing: 2px;
		margin-top: -120px;
	}

	.mine-bg-img2 {
		width: 729px;
		height: 334px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.mine-bg-item-sec1 {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

	}

	.cp-title {
		font-size: 28px;
		font-weight: 400;
		color: #FFFFFF;
	}

	.cp-num {
		margin-left: 16px;
		font-size: 38px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.addr-con {
		margin-top: 10px;
	}

	.icon-addr {
		width: 64px;
		height: 64px;
	}

	.addr-title {
		font-size: 30px;
		font-weight: 600;
		color: #FFFFFF;
	}

	.addr-desc {
		max-width: 446px;
		margin-left: 20px;
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		word-wrap: break-word;
		word-break: break-all;
		display: table-cell;
		vertical-align: middle;
	}

	.copy {
		width: 35px;
		height: 35px;
		margin-left: 5px;
		vertical-align: bottom;
	}

	.item1 {
		height: 286px;
	}

	.item-icon1 {
		width: 84px;
		height: 84px;
		margin-bottom: 17px;
	}

	.item-sec {
		width: 690px;
		height: 210px;
		background: #FFFFFF;
		border-radius: 20px;
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 26px;
		margin: 0 auto;
		padding: 0 80px;
		box-sizing: border-box;
		position: absolute;
		top: -28px;
		left: 0;
		right: 0;
	}

	.item-icon {
		width: 84px;
		height: 84px;
		margin-bottom: 12px;
	}

	.mine-banner {
		width: 690px;
		height: 274px;
		margin-top: 35px;
	}

	.shadow {
		width: 615px;
		height: 197px;
		background: rgba(0, 0, 0, 0.07);
		border-radius: 20px;
		filter: blur(11px);
		margin-left: 68px;
	}

	.top-shadow {
		width: 615px;
		height: 220px;
		background: rgba(69, 69, 69, 0.36);
		border-radius: 20px;
		filter: blur(19px);
		margin-left: 38px;
		margin-top: 32px;
	}

	.kefu-btn {
		width: 84px !important;
		padding: 0;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	.kefu-title {
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 26px;
	}

	.support-con {
		position: fixed;
		bottom: 88px;
		left: 0;
		width: 100%;
		background: #FFFFFF;
		height: 64px;
		padding-bottom: 10px;
	}

	.support-con img {
		width: 64px;
		height: 64px;
		margin-right: 10px;
	}

	.support-con div {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}

	.cp-wrap {
		width: 690px;
		margin: auto auto;
	}

	.cp-img {
		width: 330px;
		height: 400px;
		margin-bottom: 30px;
	}

	.cp-img2 {
		width: 330px;
		height: 330px;
		padding: 35px 0;
		margin-bottom: 30px;
	}

	.tips-model {
		position: fixed;
		bottom: 0;
		left: 0;
		top: 0;
		right: 0;
		margin: auto;
		width: 650px;
		height: 700px;
		background: #FFFFFF;
		border-radius: 22px;
		box-sizing: border-box;
		z-index: 1000;
	}

	.wx-code {
		width: 400px;
		height: 400px;
		margin-bottom: 30px;
	}

	.tips {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		letter-spacing: 2px;
	}

	.loginout-wrap {
		position: fixed;
		top: 18px;
		right: 10px;
		z-index: 100
	}

	.loginout-img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.loginout-text {
		font-size: 22px;
		font-weight: 400;
		color: #000000;
		line-height: 30px;
		letter-spacing: 1px;
	}

	.compound-wrap {
		margin: 10px auto 40px;
	}

	.compound {
		width: 50px;
		height: 50px;
	}

	.compound-text {
		margin-top: 17px;
		font-size: 30px;
		font-weight: 600;
		color: #000000;
	}
</style>

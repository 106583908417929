<template>
	<div class="container" :style="'height:'+height" v-show="show">
		<div style='background:#FEFFD3;width:100%;height:100%;position:fixed;' v-show="yichaikai == 1"></div>
		<div v-if='yichaikai == 0' class="bg">
			<img src="../assets/result_bg.png" class="result-bg" />
			<div class="flex-column align-center result-mh-sec">
				<img src="../assets/manghe.png" class="result-mh-img " :class="isChai?'shaky':''"
					:animation='animationData' />
				<div class="daojishi" @click="open(0)">不拆</div>
				<!-- <div class="btn-ret" @click="open(1)">立刻拆开（{{suplusTimes}}s）</div> -->
				<div class="btn-ret" @click="open(1)">立刻拆开</div>
			</div>
		</div>
	</div>
</template>

<script>
	import wx from 'weixin-js-sdk'
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		data() {
			return {
				animationData: {},
				isChai: 0,
				yichaikai: 0,
				suplusTimes: 120,
				height: window.innerHeight + 'px',
				djs: '',
				orderId: '',
				payType:0,
				show:1
			}
		},
		methods: {
			open(type) {
				let that = this;
				if (type == 0) {
					clearInterval(that.djs)
					that.$router.replace('/collect')
				} else {
					that.isChai = true;
					clearInterval(that.djs)
					if (that.yichaikai == 1) {
						that.$router.replace('/collect')
					} else if (that.yichaikai == 0) {
						let params = new FormData()
						params.append('orderId', this.orderId);
						this.axios.post(config.requestUrl + '/front/order/setOpenBlindBox', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							console.log(response)
							if (response.data.code == 0) {
								setTimeout(function() {
									that.yichaikai = 1
									that.isChai = false;
									setTimeout(() => {
										that.$router.replace('/collect')
									}, 1000)
								}, 1500)
							} else {
								let msg = response.data.msg;
								const str = "未获取到用户信息";
								const str2 = "登录已失效"
								if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
									localStorage.removeItem("user");
									that.$store.commit("clearUser");
									that.$store.commit("clearCode");
									setTimeout(() => {
										this.$router.push('/')
									}, 1200)
								}
								console.log(response.data.msg)
								Toast.fail(response.data.msg);
							}
						}, response => {
							Toast.fail('获取失败，请稍后重试');
						})

					}
				}
			},
			getPayRes() {
				const that = this;
				Toast.loading({
					message: '正在获取支付结果...' ,
					forbidClick: true,
					duration: 0,
				});
				that.count = that.count + 1;
				let orderid = that.orderId;
				let params = new FormData()
				params.append('orderId',orderid)
				that.axios.post(config.requestUrl + '/front/order/queryOrderStatus', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						if (response.data.obj.orderStatus == 3 || response.data.obj
							.orderStatus == 5 ||
							response.data.obj.orderStatus == 2) {
							Toast.success('支付成功')
							this.show=1;
							// this.djs = setInterval(() => {
							// 	if (this.suplusTimes > 0) {
							// 		this.suplusTimes = parseInt(this.suplusTimes) - 1
							// 	} else {
							// 		this.suplusTimes = 0;
							// 		clearInterval(this.djs);
							// 		this.open(1);
							// 	}
							// }, 1000)
						} else {
							that.getPayResInteval = setTimeout(() => {
								that.getPayRes();
							}, 2000)
							
						}
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			
			},
		},
		beforeRouteLeave(to, from, next) {
			clearInterval(this.djs);
			next();
		},
		mounted() {
			const orderId = this.$route.query.orderId;
			if (!orderId) {
				this.$router.push('/')
			}
			console.log(this.$route.query)
			this.orderId = orderId;
			if(this.$route.query.token){
				let token =this.$route.query.token;
				this.$store.commit('setToken', token);
				let user = {}
				if (localStorage.getItem("user")) {
					user = JSON.parse(localStorage.getItem("user"))
					user.token = token;
				} else {
					user = {
						token
					}
				}
				localStorage.setItem('user', JSON.stringify(user))
			}
			util.wxConfig();
			if(this.$route.query.payType && this.$route.query.payType == 10){
				this.show=0;
				this.getPayRes();
			}
			// else{
			// 	this.djs = setInterval(() => {
			// 		if (this.suplusTimes > 0) {
			// 			this.suplusTimes = parseInt(this.suplusTimes) - 1
			// 		} else {
			// 			this.suplusTimes = 0;
			// 			clearInterval(this.djs);
			// 			this.open(1);
			// 		}
			// 	}, 1000)
			// }
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #000000;
		min-height: 100%;
	}

	.bg {
		background-color: #000000;
	}

	.title-img {
		width: 750px;
		height: 48px;
		position: fixed;
		left: 0;
		top: 40px;
		box-sizing: border-box;
		z-index: 1000;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 28px;
		text-align: center;
	}

	.back-icon {
		width: 46px;
		height: 46px;
		padding: 20px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto 0;
	}



	.result-bg {
		width: 750px;
		height: 1084px;
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background: #000000;
	}

	.result-mh-sec {
		position: absolute;
		top: 250px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.result-mh-img {
		width: 505px;
		height: 505px;
	}

	.daojishi {
		width: 423px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin-top: 224px;
	}

	.btn-ret {
		width: 423px;
		height: 70px;
		background: #FF5E38;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin-top: 30px;
	}

	/* 拆开时的抖动效果 */
	.shaky {
		display: inline-block;
		padding: 1px;
		font-size: 12px;
		-webkit-transform-origin: center center;
		-ms-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-name: shaky-slow;
		-ms-animation-name: shaky-slow;
		animation-name: shaky-slow;
		-webkit-animation-duration: 2s;
		-ms-animation-duration: 12s;
		animation-duration: 2s;
		-webkit-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		-webkit-animation-timing-function: ease-in-out;
		-ms-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		-webkit-animation-delay: 0s;
		-ms-animation-delay: 0s;
		animation-delay: 0s;
		-webkit-animation-play-state: running;
		-ms-animation-play-state: running;
		animation-play-state: running;
	}

	@-webkit-keyframes shaky-slow {
		0% {
			-webkit-transform: translate(0px, 0px) rotate(0deg);
		}

		2% {
			-webkit-transform: translate(-1px, 1.5px) rotate(1.5deg);
		}

		4% {
			-webkit-transform: translate(1.3px, 0px) rotate(-0.5deg);
		}

		6% {
			-webkit-transform: translate(1.4px, 1.4px) rotate(-2deg);
		}

		8% {
			-webkit-transform: translate(-1.3px, -1px) rotate(-1.5deg);
		}

		10% {
			-webkit-transform: translate(1.4px, 0px) rotate(-2deg);
		}

		12% {
			-webkit-transform: translate(-1.3px, -1px) rotate(-2deg);
		}

		14% {
			-webkit-transform: translate(1.5px, 1.3px) rotate(1.5deg);
		}

		16% {
			-webkit-transform: translate(1.5px, -1.5px) rotate(-1.5deg);
		}

		18% {
			-webkit-transform: translate(1.3px, -1.3px) rotate(-2deg);
		}

		20% {
			-webkit-transform: translate(1px, 1px) rotate(-0.5deg);
		}

		22% {
			-webkit-transform: translate(1.3px, 1.5px) rotate(-2deg);
		}

		24% {
			-webkit-transform: translate(-1.4px, -1px) rotate(2deg);
		}

		26% {
			-webkit-transform: translate(1.3px, -1.3px) rotate(0.5deg);
		}

		28% {
			-webkit-transform: translate(1.6px, -1.6px) rotate(-2deg);
		}

		30% {
			-webkit-transform: translate(-1.3px, -1.3px) rotate(-1.5deg);
		}

		32% {
			-webkit-transform: translate(-1px, 0px) rotate(2deg);
		}

		34% {
			-webkit-transform: translate(1.3px, 1.3px) rotate(-0.5deg);
		}

		36% {
			-webkit-transform: translate(1.3px, 1.6px) rotate(1.5deg);
		}

		38% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(1.5deg);
		}

		40% {
			-webkit-transform: translate(-1.4px, -1px) rotate(-0.5deg);
		}

		42% {
			-webkit-transform: translate(-1.4px, 1.3px) rotate(-0.5deg);
		}

		44% {
			-webkit-transform: translate(-1.6px, 1.4px) rotate(0.5deg);
		}

		46% {
			-webkit-transform: translate(-2.1px, -1.3px) rotate(-0.5deg);
		}

		48% {
			-webkit-transform: translate(1px, 1.6px) rotate(1.5deg);
		}

		50% {
			-webkit-transform: translate(1.6px, 1.6px) rotate(1.5deg);
		}

		52% {
			-webkit-transform: translate(-1.4px, 1.6px) rotate(0.5deg);
		}

		54% {
			-webkit-transform: translate(1.6px, -1px) rotate(-2deg);
		}

		56% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(-2deg);
		}

		58% {
			-webkit-transform: translate(-1.3px, -1.6px) rotate(0.5deg);
		}

		60% {
			-webkit-transform: translate(1.3px, 1.6px) rotate(-0.5deg);
		}

		62% {
			-webkit-transform: translate(0px, 0px) rotate(-1.5deg);
		}

		64% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		66% {
			-webkit-transform: translate(1.6px, -1.6px) rotate(0.5deg);
		}

		68% {
			-webkit-transform: translate(0px, -1.6px) rotate(-2deg);
		}

		70% {
			-webkit-transform: translate(-1.6px, 1px) rotate(1.5deg);
		}

		72% {
			-webkit-transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		74% {
			-webkit-transform: translate(1.3px, -1.6px) rotate(-0.5deg);
		}

		76% {
			-webkit-transform: translate(1.4px, 1px) rotate(-0.5deg);
		}

		78% {
			-webkit-transform: translate(-1px, 1.4px) rotate(2deg);
		}

		80% {
			-webkit-transform: translate(1.4px, 1.6px) rotate(2deg);
		}

		82% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-0.5deg);
		}

		84% {
			-webkit-transform: translate(-1.4px, 1.4px) rotate(-2deg);
		}

		86% {
			-webkit-transform: translate(1px, 1.4px) rotate(-2deg);
		}

		88% {
			-webkit-transform: translate(-1.4px, 1.4px) rotate(-1.5deg);
		}

		90% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		92% {
			-webkit-transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		94% {
			-webkit-transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		96% {
			-webkit-transform: translate(-1.4px, 1.3px) rotate(-2deg);
		}

		98% {
			-webkit-transform: translate(1.3px, 1px) rotate(-0.5deg);
		}
	}

	@keyframes shaky-slow {
		0% {
			transform: translate(0px, 0px) rotate(0deg);
		}

		2% {
			transform: translate(-1px, 1.5px) rotate(1.5deg);
		}

		4% {
			transform: translate(1.3px, 0px) rotate(-0.5deg);
		}

		6% {
			transform: translate(1.4px, 1.4px) rotate(-2deg);
		}

		8% {
			transform: translate(-1.3px, -1px) rotate(-1.5deg);
		}

		10% {
			transform: translate(1.4px, 0px) rotate(-2deg);
		}

		12% {
			transform: translate(-1.3px, -1px) rotate(-2deg);
		}

		14% {
			transform: translate(1.5px, 1.3px) rotate(1.5deg);
		}

		16% {
			transform: translate(1.5px, -1.5px) rotate(-1.5deg);
		}

		18% {
			transform: translate(1.3px, -1.3px) rotate(-2deg);
		}

		20% {
			transform: translate(1px, 1px) rotate(-0.5deg);
		}

		22% {
			transform: translate(1.3px, 1.5px) rotate(-2deg);
		}

		24% {
			transform: translate(-1.4px, -1px) rotate(2deg);
		}

		26% {
			transform: translate(1.3px, -1.3px) rotate(0.5deg);
		}

		28% {
			transform: translate(1.6px, -1.6px) rotate(-1.5deg);
		}

		30% {
			transform: translate(-1.3px, -1.3px) rotate(-1.5deg);
		}

		32% {
			transform: translate(-1px, 0px) rotate(2deg);
		}

		34% {
			transform: translate(1.3px, 1.3px) rotate(-0.5deg);
		}

		36% {
			transform: translate(1.3px, 1.6px) rotate(1.5deg);
		}

		38% {
			transform: translate(1.3px, -1.6px) rotate(1.5deg);
		}

		40% {
			transform: translate(-1.4px, -1px) rotate(-0.5deg);
		}

		42% {
			transform: translate(-1.4px, 1.3px) rotate(-0.5deg);
		}

		44% {
			transform: translate(-1.6px, 1.4px) rotate(0.5deg);
		}

		46% {
			transform: translate(-2.1px, -1.3px) rotate(-0.5deg);
		}

		48% {
			transform: translate(1px, 1.6px) rotate(1.5deg);
		}

		50% {
			transform: translate(1.6px, 1.6px) rotate(1.5deg);
		}

		52% {
			transform: translate(-1.4px, 1.6px) rotate(0.5deg);
		}

		54% {
			transform: translate(1.6px, -1px) rotate(-2deg);
		}

		56% {
			transform: translate(1.3px, -1.6px) rotate(-2deg);
		}

		58% {
			transform: translate(-1.3px, -1.6px) rotate(0.5deg);
		}

		60% {
			transform: translate(1.3px, 1.6px) rotate(-0.5deg);
		}

		62% {
			transform: translate(0px, 0px) rotate(-1.5deg);
		}

		64% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		66% {
			transform: translate(1.6px, -1.6px) rotate(0.5deg);
		}

		68% {
			transform: translate(0px, -1.6px) rotate(-2deg);
		}

		70% {
			transform: translate(-1.6px, 1px) rotate(1.5deg);
		}

		72% {
			transform: translate(-1.6px, 1.6px) rotate(2deg);
		}

		74% {
			transform: translate(1.3px, -1.6px) rotate(-0.5deg);
		}

		76% {
			transform: translate(1.4px, 1px) rotate(-0.5deg);
		}

		78% {
			transform: translate(-1px, 1.4px) rotate(2deg);
		}

		80% {
			transform: translate(1.4px, 1.6px) rotate(2deg);
		}

		82% {
			transform: translate(-1.6px, -1.6px) rotate(-0.5deg);
		}

		84% {
			transform: translate(-1.4px, 1.4px) rotate(-2deg);
		}

		86% {
			transform: translate(1px, 1.4px) rotate(-2deg);
		}

		88% {
			transform: translate(-1.4px, 1.4px) rotate(-1.5deg);
		}

		90% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		92% {
			transform: translate(-1.4px, 1.6px) rotate(2deg);
		}

		94% {
			transform: translate(-1.6px, -1.6px) rotate(-2deg);
		}

		96% {
			transform: translate(-1.4px, 1.3px) rotate(-2deg);
		}

		98% {
			transform: translate(1.3px, 1px) rotate(-0.5deg);
		}
	}


	/* 拆盒结果 */
	.container {}

	.top-sec {
		background: #000000;
		padding: 43px 0 36px 0;
		text-align: center;
	}

	.share-img-bg {
		width: 646px;
		height: 774px;
	}

	.title {
		width: 540px;
		height: 165px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 48px;
		letter-spacing: 2px;
		position: absolute;
		top: 51px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}

	.good-img {
		width: 428px;
		height: 428px;
		border-radius: 50%;
		position: absolute;
		left: 0;
		right: 0;
		top: 294px;
		margin: 0 auto;
	}

	.hand-left-img {
		width: 268px;
		height: 327px;
		position: absolute;
		left: 0;
		top: 526px;
	}

	.hand-right-img {
		width: 253px;
		height: 272px;
		position: absolute;
		right: 0;
		top: 288px;
	}

	.save {
		width: 423px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin: 60px auto 0 auto;
	}

	.huishou {
		width: 423px;
		height: 70px;
		background: #FFFFFF;
		border-radius: 38px;
		border: 4px solid #000000;
		font-size: 34px;
		font-weight: bold;
		color: #000000;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
		margin: 50px auto 0 auto;
	}

	/* 提示卡结果弹窗 */
	.tishi-result-model {
		width: 500px;
		height: 596px;
		background: #FF8B70;
		border-radius: 20px;
		border: 4px solid #000000;
		box-sizing: border-box;
		position: fixed;
		top: 280px;
		/* bottom: 0; */
		left: 0;
		right: 0;
		margin: auto auto;
	}

	.tishi-result-bg {
		width: 500px;
		height: 596px;
		border-radius: 20px;
	}

	.result-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 500px;
		height: 596px;
	}

	.tip-kuang-img {
		width: 390px;
		height: 323px;
		margin-top: 75px;
	}

	.result-detail {
		position: absolute;
		top: 75px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 500px;
		text-align: center;
	}

	.result-name {
		font-size: 34px;
		font-weight: bold;
		color: #000000;
		line-height: 48px;
		letter-spacing: 4px;
		margin-top: 35px;
		padding-bottom: 44px;
		border-bottom: 1px dashed #979797;
		width: 302px;
		text-align: center;
	}

	.result-name text {
		color: #FF0000;
	}

	.result-title {
		font-size: 26px;
		color: #000000;
		line-height: 44px;
		margin-top: 44px;
	}

	.know-btn {
		width: 423px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 7px;
		text-align: center;
		margin-top: 176px;
	}

	/* 回收信息展示弹窗 */
	.huishou-model {
		width: 500px;
		height: 700px;
		background: #FF8B70;
		border-radius: 20px;
		box-sizing: border-box;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto auto;
	}

	.huishou-bg {
		width: 500px;
		height: 700px;
	}

	.confirm-hs-btn {
		width: 423px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		margin-top: 132px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
	}

	.fq-hs-btn {
		width: 423px;
		height: 70px;
		background: #6236FF;
		border-radius: 38px;
		margin-top: 40px;
		font-size: 34px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 70px;
		letter-spacing: 2px;
		text-align: center;
	}

	.fahuo-title {}
</style>

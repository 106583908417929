import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import 'lib-flexible'
Vue.config.productionTip = false
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css';
Vue.use(VueAwesomeSwiper)

import Vuex from 'vuex';
import axios from "axios";
Vue.use(Vuex)

import config from './config/config.js';
import emoji from './config/emoji.js';
Vue.directive('emoji', emoji)
import util from './config/util.js'
import resetFont from 'wx-font-reset';
resetFont();
const store = new Vuex.Store({
	state: {
		user: {
			token: ''
		},
		code: '',
		goodsList: [],
		checkToken: 0
	},
	mutations: {
		setToken(state, token) {
			state.user.token = token
		},
		setUser(state, user) {
			state.user = user;
		},
		setCode(state, code) {
			state.code = code
		},
		clearCode(state) {
			state.code = ''
		},
		clearUser(state) {
			state.user = {}
		},
		setOpenId(state, openid) {
			state.user.openid = openid
		},
		setGoodsList(state, goodsList) {
			state.goodsList = goodsList
		},
		setCheckToken(state, checkToken) {
			state.checkToken = checkToken
		}
	}
})



router.beforeEach((to, from, next) => {
	document.title = '万物数字藏品'
	if (localStorage.getItem("user") != null && localStorage.getItem("user")) {
		//存在登录信息就从localStorage中提取状态再传值给vuex中
		let user = JSON.parse(localStorage.getItem("user"))
		store.commit("setUser", user);
		if (!store.state.checkToken) {
			let params = new FormData()
			axios.post(config.requestUrl + '/front/user/getUserTokenInfo', params, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'token': store.state.user.token
				}
			}).then(response => {
				store.commit('setCheckToken', 1)
				if (response.data.code == 0) {
					if (response.data.obj < 600) {
						localStorage.removeItem("user");
						store.commit("clearUser");
						store.commit("clearCode");
					}
				} else {
					localStorage.removeItem("user");
					store.commit("clearUser");
					store.commit("clearCode");
				}
				var token = store.state.user.token;
				if (!token && !store.state.code) {
					if (isWeiXin()) {
						let appid =
							"wx23406d34d48c253c"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
						let redirect_uri = encodeURIComponent(
							config.authHost + to.fullPath
						);
						let url;
						if (!config.env) {
							url = config.authUrl + '/?returnUrl=' + redirect_uri
						} else {
							url =
								"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
								appid + "&redirect_uri=" + redirect_uri +
								"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
						}
						// 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。
						let code = GetParam(window.location.href.split("#")[0], "code");
						if (!code) {
							window.location.href = url;
						} else if (!store.state.user.openid) {
							code = GetParam(window.location.href, "code");
							let data = {
								code
							};
							store.commit('setCode', code)
							//默认登录
							let params = new FormData()
							params.append('code', code)
							axios.post(config.requestUrl + '/front/weChatCodeLogin', params, {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}).then(response => {
								console.log(response)
								if (response.data.code == 0) {
									if (response.data.obj.status == 1) {
										let user = response.data.obj
										user.randName = util.toString();
										store.commit('setUser', user)
										localStorage.setItem('user', JSON.stringify(user))

										next();
									} else {
										if (!response.data.obj.openId) {
											window.location.href = url;
										} else {
											let user = {
												openid: response.data.obj.openId
											}
											store.commit('setOpenId', response.data.obj.openId);
											localStorage.setItem('user', JSON.stringify(user))
											next();
										}

									}
								} else {
									let user = {
										openid: response.data.obj.openId
									}
									store.commit('setOpenId', response.data.obj.openId);
									localStorage.setItem('user', JSON.stringify(user))
									next();
								}
							}, response => {
								next();
							})
						} else {
							next();
						}
					} else {
						next();
					}
				} else {
					next();
				}
			}, response => {
				store.commit('setCheckToken', 1)
				var token = store.state.user.token;
				if (!token && !store.state.code) {
					if (isWeiXin()) {
						let appid =
							"wx23406d34d48c253c"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
						let redirect_uri = encodeURIComponent(
							config.authHost + to.fullPath
						);
						let url;
						if (!config.env) {
							url = config.authUrl + '/?returnUrl=' + redirect_uri
						} else {
							url =
								"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
								appid + "&redirect_uri=" + redirect_uri +
								"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
						}
						// 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。
						let code = GetParam(window.location.href.split("#")[0], "code");
						if (!code) {
							window.location.href = url;
						} else if (!store.state.user.openid) {
							code = GetParam(window.location.href, "code");
							let data = {
								code
							};
							store.commit('setCode', code)
							//默认登录
							let params = new FormData()
							params.append('code', code)
							axios.post(config.requestUrl + '/front/weChatCodeLogin', params, {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}).then(response => {
								console.log(response)
								if (response.data.code == 0) {
									if (response.data.obj.status == 1) {
										let user = response.data.obj
										user.randName = util.toString();
										store.commit('setUser', user)
										localStorage.setItem('user', JSON.stringify(user))

										next();
									} else {
										if (!response.data.obj.openId) {
											window.location.href = url;
										} else {
											let user = {
												openid: response.data.obj.openId
											}
											store.commit('setOpenId', response.data.obj.openId);
											localStorage.setItem('user', JSON.stringify(user))
											next();
										}

									}
								} else {
									let user = {
										openid: response.data.obj.openId
									}
									store.commit('setOpenId', response.data.obj.openId);
									localStorage.setItem('user', JSON.stringify(user))
									next();
								}
							}, response => {
								next();
							})
						} else {
							next();
						}
					} else {
						next();
					}
				} else {
					next();
				}
			})
		} else {
			next();
		}
	} else {
		store.commit("clearUser");
		var token = store.state.user.token;
		if (!token && !store.state.code) {
			if (isWeiXin()) {
				let appid =
					"wx23406d34d48c253c"; //实际公众号appid，这里的appid对应的微信应用一定是绑定当前h5项目所在线上域名，也就是需要在微信开放平台绑定js安全域名
				let redirect_uri = encodeURIComponent(
					config.authHost + to.fullPath
				);
				let url;
				if (!config.env) {
					url = config.authUrl + '/?returnUrl=' + redirect_uri
				} else {
					url =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
						appid + "&redirect_uri=" + redirect_uri +
						"&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect";
				}

				// 以snsapi_base为scope发起的网页授权，是用来获取进入页面的用户的openid的，并且是静默授权并自动跳转到回调页的.（静默的另一种：对于已关注公众号的用户，如果用户从公众号的会话或者自定义菜单进入本公众号的网页授权页，即使是scope为snsapi_userinfo，也是静默授权，用户无感知。）以snsapi_userinfo为scope发起的网页授权，是用来获取用户的基本信息的。但这种授权需要用户手动同意，并且由于用户同意过，所以无须关注，就可在授权后获取该用户的基本信息。
				let code = GetParam(window.location.href.split("#")[0], "code");
				if (!code) {
					window.location.href = url;
				} else if (!store.state.user.openid) {
					code = GetParam(window.location.href, "code");
					let data = {
						code
					};
					store.commit('setCode', code)
					//默认登录
					let params = new FormData()
					params.append('code', code)
					axios.post(config.requestUrl + '/front/weChatCodeLogin', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						console.log(response)
						if (response.data.code == 0) {
							if (response.data.obj.status == 1) {
								let user = response.data.obj
								user.randName = util.toString();
								store.commit('setUser', user)
								localStorage.setItem('user', JSON.stringify(user))

								next();
							} else {
								if (!response.data.obj.openId) {
									window.location.href = url;
								} else {
									let user = {
										openid: response.data.obj.openId
									}
									store.commit('setOpenId', response.data.obj.openId);
									localStorage.setItem('user', JSON.stringify(user))
									next();
								}

							}
						} else {
							let user = {
								openid: response.data.obj.openId
							}
							store.commit('setOpenId', response.data.obj.openId);
							localStorage.setItem('user', JSON.stringify(user))
							next();
						}
					}, response => {
						next();
					})
				} else {
					next();
				}
			} else {
				next();
			}
		} else {
			next();
		}
	}
})

//判断是否是微信浏览器的函数
const isWeiXin = function() {
	//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
	var ua = window.navigator.userAgent.toLowerCase();
	// alert(JSON.stringify(ua))
	//通过正则表达式匹配ua中是否含有MicroMessenger字符串
	if (ua.match(/MicroMessenger/i) == 'micromessenger') {
		return true;
	} else {
		return false;
	}
}
const GetParam = function(url, code) {
	url = url + "";
	let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
	let reg = eval(regstr);
	//eval可以将 regstr字符串转换为 正则表达式
	let result = url.match(reg);
	if (result && result[2]) {
		return result[2];
	}
}


Vue.directive('real-img', async function(el, binding) { //指令名称为：real-img
	let imgURL = binding.value; //获取图片地址
	if (imgURL) {
		let exist = await imageIsExist(imgURL);
		if (exist) {
			el.setAttribute('src', imgURL);
		}
	}
})

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
	return new Promise((resolve) => {
		var img = new Image();
		img.onload = function() {
			if (this.complete == true) {
				resolve(true);
				img = null;
			}
		}
		img.onerror = function() {
			resolve(false);
			img = null;
		}
		img.src = url;
	})
}


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

<template>
	<div class="container">
		<div class="content">
			<div class="pl40">
				<div class="item">转赠条件：现阶段不限</div>
				<div class="item">转赠费用：现阶段免费</div>
				<div class="item">转赠流程：为保障安全性，提交后需经平台确认。</div>
				<div class="textarea-title">请输入对方的区块链地址：</div>
				<textarea v-model="toAddress" @input="inputAddress" rows="" cols="" class="textarea-sec"></textarea>
			</div>
			<div class="flex-column align-center justify-center" v-if="sendSucc">
				<img src='../assets/succ_icon.png' class="success-icon" />
				<div class="success-text">提交成功等待审核</div>
			</div>
			<div class="remind-text">自愿转赠后，持有人将变更为对方，请谨慎操作。</div>
			<div class="flex-row align-center btn-sec space-between">
				<div class="submit-btn" @click="confirm()">提交</div>
				<div class="cancle-btn" @click="cancel()">{{sendSucc?'返回':'取消'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				productId: '',
				toAddress: '',
				sending: 0,
				sendSucc: 0
			}
		},
		created() {
			const productId = this.$route.query.productId;
			if (!productId) {
				this.$router.push('/')
			}
			this.productId = productId;
			util.wxConfig();
		},
		methods: {
			inputAddress(e) {
				this.toAddress = e.target.value;
			},
			confirm() {
				if (this.sendSucc) {
					Toast.success('已提交申请,请耐心等待审核');
				}
				if (this.sending) {
					return;
				}
				if (!this.toAddress) {
					Toast.fail('请输入对方地址');
					return;
				}
				Toast.loading({
					message: '加载中...',
					forbidClick: true,
					duration: 0,
				});
				let params = new FormData()
				params.append('productEntryId', this.productId)
				params.append('toAddress', this.toAddress)
				this.axios.post(config.requestUrl + '/front/transfer/doHandselApply', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.sendSucc = 1;
						Toast.clear()
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					this.getBox = 0;
					Toast.fail('获取失败，请稍后重试');
				})
			},
			cancel() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		position: fixed;
		background: #333333;
	}

	.content {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 630px;
		height: 1200px;
		background: #FFFFFF;
		border-radius: 30px;
		padding-top: 60px;
		box-sizing: border-box;
	}

	.pl40 {
		padding-left: 40px;
	}

	.item {
		font-size: 24px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.textarea-title {
		margin-top: 60px;
		font-size: 24px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
	}

	.textarea-sec {
		margin-top: 20px;
		width: 550px;
		height: 140px;
		background: #F5F5F5;
		border-radius: 10px;
		border: 1px solid #EEEEEE;
		padding: 10px;
		box-sizing: border-box;
	}

	.success-icon {
		width: 128px;
		height: 128px;
		margin-top: 106px;
	}

	.success-text {
		margin-top: 20px;
		font-size: 20px;
		font-weight: 600;
		color: #000000;
		line-height: 28px;
		text-align: center;
	}

	.remind-text {
		margin-top: 298px;
		font-size: 24px;
		font-weight: 600;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 40px;
	}

	.btn-sec {
		margin-top: 30px;
		padding: 0 16px;
	}

	.submit-btn {
		width: 270px;
		height: 60px;
		background: #FF5E38;
		border-radius: 30px;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
	}

	.cancle-btn {
		width: 270px;
		height: 60px;
		border-radius: 30px;
		border: 1px solid #EEEEEE;
		font-size: 20px;
		color: #000000;
		line-height: 60px;
		text-align: center;
	}
</style>

<template>
	<div class="public">
		<div class="model" v-on:click.stop="$emit('close-public')"></div>
		<div class="tips-model flex-column align-center justify-center">
			<img src="../assets/qrcode.jpg" class="wx-code">
			<div class="tips">{{msg}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		}
	}
</script>

<style scoped="scoped">
	.tips-model {
		position: fixed;
		bottom: 0;
		left: 0;
		top: 0;
		right: 0;
		margin: auto;
		width: 650px;
		height: 700px;
		background: #FFFFFF;
		border-radius: 22px;
		box-sizing: border-box;
		z-index: 1000;
	}

	.wx-code {
		width: 400px;
		height: 400px;
		margin-bottom: 30px;
	}

	.tips {
		font-size: 30px;
		font-weight: 600;
		color: #000000;
		line-height: 42px;
		letter-spacing: 2px;
	}
</style>

<template>
	<div class="container ">
		<div class="wrap flex-row align-center space-between flex-wrap">
			<div v-for="(item,index) in list" v-bind:key="index" class="item flex-column align-center justify-center">
				<img :src="item.mianPic" class="item-img" />
				<div class="btn" @click.stop="compound(index)">合成</div>
			</div>
			<div class="empty flex-column align-center justify-center" v-if="list.length == 0">
				<div class="empty-tip">没有可合成的新藏品</div>
				<div class="empty-tip2">（原藏品需要先完成上链）</div>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		Toast,
		Dialog
	} from 'vant';
	import 'vant/lib/toast/index.css';
	import 'vant/lib/dialog/index.css';
	import config from '../config/config.js';
	import util from '../config/util.js';
	export default {
		data() {
			return {
				list: [],
				more: true,
				pageNum: 1,
				pageSize: 10,
				loading: 0,
				windowHeight: 0,
				offsetHeight: 0,
			}
		},
		methods: {
			compound(index) {
				const that = this;
				let item=that.list[index];
				Dialog.confirm({
						title: '确认合成',
						// message: `本次合成会消耗${item.consumeCount}件藏品，是否确定？`,
						message: `本次合成会消耗藏品，是否确定？`,
					})
					.then(() => {
						let params = new FormData()
						params.append('id',item.id)
						Toast.loading({
							message: '合成中...',
							forbidClick: true,
							duration: 0,
						});
						that.axios.post(config.requestUrl + '/front/merge/doMerge', params, {
							headers: {
								'Content-Type': 'multipart/form-data',
								'token': this.$store.state.user.token
							}
						}).then(response => {
							if (response.data.code == 0) {
								Toast.success('合成成功');
								that.getMergeConfigListByUserId();
							} else {
								Toast.fail(response.data.msg);
							}
						}, response => {
							Toast.fail('获取失败，请稍后重试');
						})
					})
					.catch(() => {
						console.log('取消')
						// on cancel
					});
			},
			getMergeConfigListByUserId() {
				console.log('qwer')
				const that = this;
				let params = new FormData()
				that.axios.post(config.requestUrl + '/front/merge/getMergeConfigListByUserId', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.list = response.data.obj;
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			}
		},
		mounted() {
			// window.addEventListener('scroll', this.onScroll, false);
			// this.windowHeight = window.innerHeight; //窗口高度
			// this.offsetHeight = this.$refs.getheight.offsetHeight;
			this.getMergeConfigListByUserId();
		},
	}
</script>

<style scoped="scoped">
	.container {
		width: 100%;
		background: #000000;
		min-height: 100%;
	}

	.wrap {
		width: 100%;
		padding: 0 75px;
		box-sizing: border-box;
	}

	.item {
		margin-top: 40px;
		width: 290px;
	}

	.item-img {
		width: 290px;
		height: 324px;
		margin-bottom: 20px;
	}

	.btn {
		width: 150px;
		height: 50px;
		background: #6236FF;
		border-radius: 25px;
		font-size: 20px;
		font-weight: 400;
		line-height: 50px;
		color: #FFFFFF;
		text-align: center;
	}
	.empty{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}
	.empty-tip{
		font-size: 40px;
		color: #FFFFFF;
	}
	.empty-tip2{
		font-size: 34px;
		margin-top: 15px;
		color: #FFFFFF;
	}
</style>

<template>
	<div class="container" ref="getheight">
		<LoginWrap v-show="showLogin" v-on:close-login="closeLogin()" />
		<PublicWrap :msg="msg" v-show="showPublic" v-on:close-public="closePublic()" />
		<!-- <Barrage /> -->
		<div class="title-img column justify-center ">
			<img src='../assets/title_img.png' />
		</div>
		<div @click="login()" class="login" v-if="!islogin">登录/注册</div>
		<div v-else class="flex-row align-center login-wrap">
			<img v-if="userPic" :src="userPic" class="head-img" />
			<img v-if="!userPic" src="../assets/good1.png" class="head-img" />
			<div class="nickname">{{nickname}}</div>
		</div>
		<swiper ref="mySwiper" :options="swiperOptions" class="myswiper">
			<swiper-slide v-for="(item,index) in banner" v-bind:key="index"><img :src="item.path" class="banner-img"
					alt=""></swiper-slide>
		</swiper>
		<div class="goods-sec relative">
			<div class="goods-sec-absolute" ref="getGoodsHeight">
				<div class="qbmh-img">
					<img src="../assets/qbmh.png" />
				</div>
				<div class="goos-list flex-row flex-wrap align-center space-between">
					<div class="goods-item flex-column align-center relative" v-for="(item,index) in list"
						v-bind:key="index" @click="navDetail(item.id,index)">
						<img src="../assets/time.png" v-if="item.isStart == 0" class="time-img" />
						<div class="time" v-if="item.isStart == 0">{{item.startTimeFormat}}</div>
						<img mode="widthFix" :src="item.listViewPic" class="goods-item-img2" />
						<img src="../assets/end.png" class="null-img2" v-if="item.surplusQuantity ==-99" alt="">
						<img src="../assets/sellnull.png" class="null-img" v-else-if="item.surplusQuantity <=0" alt="">
						<img src="../assets/pause.png" class="null-img"
							v-if="item.surplusQuantity >0 && item.pause == 1" alt="">
						<!-- <img mode="widthFix"
							src="https://manghe-1255429956.file.myqcloud.com/02/c7f2f16f787721c62df370aebcf1f9.jpg"
							class="goods-item-img">
						<div class="flex-row flex-row flex-wrap align-center space-between mini-sec">
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
							<img src="https://manghe-1255429956.file.myqcloud.com/9d/a25d6d812fafb184a81c77f152b387.jpg"
								class="goods-item-mini-img" />
						</div> -->
						<div class="goos-item-bot flex-column align-center">
							<div class="flex-row align-center space-between title-sec">
								<div class="flex-row align-center">
									<img src="../assets/star.png" class="goods-item-star" />
									<div class="goods-item-title">{{item.name}}</div>
								</div>
								<div class="goods-item-price">￥{{item.price}}</div>
							</div>
							<div class="goods-item-desc">{{item.summary}}</div>
						</div>
					</div>

				</div>
				<!-- <a href="../assets/yyzj.jpg" target="_blank"> -->
				<img src="../assets/yyzj.jpg" class="yyzh" alt="">
				<!-- </a> -->
			</div>

			<div class="download-app-tips flex-row align-center justify-center" v-if="downloadModal">
				<img src="../assets/close-download.png" class="close-dl-img" alt="" @click.stop="closeDownloadModal()">
				<img src="../assets/icon-download.png" class="icon-download" alt="" @click.stop="navDownload()">
			</div>
			<div class="icp" @click.stop="navBa()">
				沪ICP备17003041号-3
			</div>
		</div>
		<!-- 底部菜单 -->
		<div class="tab-footer-sec flex-row align-center" @click.stop="">
			<div class="flex-column align-center tab-footer-btn relative" @click.stop="">
				<img src='../assets/chouhe.png' class="tab-active-img" />
				<div class="tab-footer-btn-title">抽盒</div>
			</div>
			<div class="flex-column align-center tab-footer-btn " @click.stop="navCollect()">
				<img src='../assets/collect.png' class="tab-no-active-img" />
				<div>藏品</div>
			</div>
			<div class="flex-column align-center tab-footer-btn" @click.stop="navMember()">
				<img src='../assets/mine_icon.png' class="tab-no-active-img" />
				<div>我的</div>
			</div>
		</div>

		<div class="zq-model flex-column align-center" v-if="showIs">
			<img src="../assets/poster.png" alt="" class="poster">
			<img src="../assets/confirm.png" alt="" class="confirm" @click.stop="cancelShowIs()">
		</div>
		<div class="model" v-if="showIs"></div>

	</div>
</template>

<script>
	import LoginWrap from '@/components/Login.vue';
	import PublicWrap from '@/components/Public.vue';
	// import Barrage from '@/components/Barrage.vue';
	import config from '../config/config.js';
	import util from '../config/util.js';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	export default {
		name: 'carrousel',
		data() {
			return {
				swiperOptions: {
					pagination: {
						el: '.swiper-pagination'
					},
				},
				reachBottomDistance: 80,
				getBox: 0,
				pageNum: 1,
				pageSize: 10,
				more: true,
				list: [],
				banner: [],
				showPublic: 0,
				msg: '',
				minHeight: 'min-height:500px',
				Inteval: [],
				showLogin: false,
				djInt: '',
				showIs: 0,
				zqInfo: {},
				pauseInt: '',
				downloadModal: 0
			}
		},
		components: {
			LoginWrap,
			PublicWrap,
			// Barrage
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper
			},
			islogin() {
				return this.$store.state.user.token ? true : false
			},
			userPic() {
				return this.$store.state.user.userPic
			},
			nickname() {
				return this.$store.state.user.nickName ? this.$store.state.user.nickName : this.$store.state.user.name
			}
		},
		activated() {
			this.pageNum = 1;
			this.pageSize = 10;
			this.more = true;
			this.list = [];
			this.getList(1);
			if (this.islogin) {
				this.getIsLuckyNumber();
			}
		},

		mounted() {
			this.swiper.slideTo(3, 1000, false)
			this.getBanner();
			util.wxConfig();
			this.dj();
			if (this.islogin) {
				this.getIsLuckyNumber();
			}
			console.log('dasdas')
			var u = navigator.userAgent,
				app = navigator.appVersion;
			console.log(u);
			console.log(app)
			var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
			var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
			if (isAndroid) {
				if (!sessionStorage.getItem('showDownload')) {
					this.downloadModal = 1;
				}
			}
		},

		methods: {
			navMsg(){
				this.$router.push('/newsList')
			},
			navDownload() {
				this.$router.push('/download')
			},
			closeDownloadModal() {
				this.downloadModal = 0;
				sessionStorage.setItem('showDownload', 1)
			},
			cancelShowIs() {
				const that = this;
				this.showIs = false;
				let localZqInfo = localStorage.getItem('zqInfo');
				if (localZqInfo) {
					localZqInfo = JSON.parse(localZqInfo);
					localZqInfo.push(that.zqInfo.blindBoxId)
					localStorage.setItem('zqInfo', JSON.stringify(localZqInfo));
				} else {
					let zqInfo = [that.zqInfo.blindBoxId];
					localStorage.setItem('zqInfo', JSON.stringify(zqInfo));
				}
			},
			getIsLuckyNumber() {
				const that = this
				let params = new FormData()
				this.axios.post(config.requestUrl + '/front/blindBox/getIsLuckyNumber', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						let zqInfo = response.data.obj;
						that.zqInfo = zqInfo;
						// console.log(zqInfo)
						if (parseInt(zqInfo.canBuyQuantity) > 0 && zqInfo.timeIsEnd == 0 && util.getZqShowTime()) {
							let localZqInfo = localStorage.getItem('zqInfo');
							if (localZqInfo) {
								localZqInfo = JSON.parse(localZqInfo);
								if (localZqInfo.indexOf(zqInfo.blindBoxId) == -1) {
									that.showIs = 1;
								}
							} else {
								that.showIs = 1;
							}
						}
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			dj() {
				const that = this;
				that.djInt = setInterval(() => {
					const newList = that.list;
					for (let i in newList) {
						if (newList[i].isStart == 0) {
							let isStart = util.compareTime(newList[i].startTime)
							if (isStart) {
								that.list[i].isStart = isStart;
							}
						}
					}
				}, 1000)
			},
			async navMember() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					await clearInterval(this.pauseInt)
					this.$router.replace('/member')
				}
			},
			async navCollect() {
				if (!this.$store.state.user.token) {
					this.login()
				} else {
					await clearInterval(this.pauseInt)
					this.$router.replace('/collect')
				}
			},
			async navDetail(id, index) {
				let goods = this.list[index];
				await clearInterval(this.pauseInt)
				if (goods.isStart == 0) {
					// this.msg = "请关注公众号接收开售提醒"
					// this.openPublic();
					this.$router.push({
						path: "/detail",
						query: {
							id
						}
					});
				}
				//  else if (goods.surplusQuantity <= 0) {
				// 	this.msg = "请关注公众号接收发售提醒"
				// 	this.openPublic();
				// } 
				else {
					this.$router.push({
						path: "/detail",
						query: {
							id
						}
					});
				}
				// this.$router.push('/detail')

			},
			login() {
				this.showLogin = true;
				this.$forceUpdate()
			},
			openPublic() {
				this.showPublic = true;
				this.$forceUpdate()
			},
			closePublic() {
				this.showPublic = false;
				this.$forceUpdate()
			},
			closeLogin() {
				this.showLogin = false;
				this.$forceUpdate()
				if (this.islogin) {
					this.getIsLuckyNumber();
				}
			},

			getList(type) {
				const that = this;
				if (!this.getBox) {
					this.getBox = 1;
					if (type) {
						Toast.loading({
							message: '加载中...',
							forbidClick: true,
						});
					}

					let params = new FormData()
					params.append('pageNum', this.pageNum)
					params.append('pageSize', this.pageSize)

					this.axios.post(config.requestUrl + '/front/blindBox/getList', params, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}).then(response => {
						this.getBox = 0;
						if (response.data.code == 0) {
							Toast.clear()
							let list = this.list;
							let newList = response.data.obj.list;
							let pause = 0;
							console.log(list)
							for (let i in newList) {
								if (newList[i].isCrowdfunding == 1) {
									newList.splice(i, 1);
								} else {
									newList[i].startTime = newList[i].startTime.replace(/-/g, "/")
									newList[i].startTimeFormat = util.formatTime2(newList[i].startTime);
									newList[i].isStart = util.compareTime(newList[i].startTime)
									newList[i].pause = 0;
									if (newList[i].surplusQuantity > 0 && newList[i].isStart == 1 && newList[i]
										.switchStr == '关') {
										newList[i].pause = util.getPauseStatus(newList[i].startTime, newList[i]
											.luckyNumberBuyTime)
										if (newList[i].pause) {
											pause = 1;
										}
									}
									let arr = newList[i].listViewPic.split('/');
									let str = arr.pop();
									newList[i].listViewPic = config.imgUrl + str;
									let arr2 = newList[i].mianPic.split('/');
									let str2 = arr2.pop();
									newList[i].mianPic = config.imgUrl + str2;
								}
							}

							list = newList;

							if (list.length < this.pageSize) this.more = false;
							if (list.length > 3) list.length = 3;
							this.pageNum = this.pageNum + 1;
							this.list = list;
							if (pause) {
								that.pauseInt = setTimeout(() => {
									clearInterval(that.pauseInt)
									that.pageNum = 1;
									// that.list = [];
									that.getList(0)
								}, 5000)
							}
						} else {
							Toast.fail(response.data.msg);
						}
					}, response => {
						this.getBox = 0;
						Toast.fail('获取失败，请稍后重试');
					})

				}
			},

			getBanner() {
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/indexSlidesPC', params, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 0) {
						this.banner = response.data.obj;
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					Toast.fail('获取失败，请稍后重试');
				})
			},
			navBa() {
				window.location.href = "https://beian.miit.gov.cn/#/Integrated/index"
			}
		}
	}
</script>
<style scoped="scoped">
	page {
		min-height: 100%;
		background: #000000;
	}

	.container {
		position: relative;
		min-height: 100%;
		background: #000000;
	}

	.title-img {
		width: 315px;
		height: 48px;
		position: fixed;
		left: 0;
		top: 10px;
		padding-left: 30px;
		box-sizing: border-box;
		z-index: 80;
	}

	.login {
		width: 200px;
		height: 48px;
		position: fixed;
		right: 0;
		top: 10px;
		padding-left: 30px;
		box-sizing: border-box;
		z-index: 80;
		font-size: 22px;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		letter-spacing: 1px;
	}

	.login-wrap {
		position: fixed;
		right: 30px;
		top: 24px;
		padding-left: 30px;
		box-sizing: border-box;
		z-index: 80;
		font-size: 22px;
		font-weight: 400;
		color: #000000;
		line-height: 30px;
		letter-spacing: 1px;
	}

	.head-img {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		border-radius: 100%;
	}

	.title-img img {
		width: 315px;
		height: 48px;
	}

	.myswiper {
		width: 750px;
		height: 504px;
	}

	.banner-img {
		width: 750px;
		height: 504px;
	}

	.item {
		width: 375px;
		height: 375px;
		background: red;
	}

	.item2 {
		width: 375px;
		height: 375px;
		background: yellow;
	}

	.item-con {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.goods-sec-absolute {
		width: 750px;
		background: #000000;
		box-shadow: 0px -1px 0px 0px #363636;
		border-radius: 30px 30px 0px 0px;
		position: absolute;
		top: -32px;
		left: 0;
		padding-bottom: 138px;
		z-index: 10
	}

	.qbmh-img {
		width: 272px;
		height: 46px;
		margin: 27px auto 0 auto;
	}

	.qbmh-img img {
		width: 272px;
		height: 46px;
	}

	.mini-sec {
		margin: 10px 15px 0 15px;
		height: 154px;
	}

	.goos-list {
		padding: 0 20px;
		margin-top: 23px;
	}

	.goods-item {
		width: 346px;
		/* height: 507px; */
		height: 611px;
		background: #FFFFFF;
		border-radius: 30px;
		border: 2px solid #000000;
		margin-top: 23px;
		box-sizing: border-box;
	}

	.new-img {
		width: 158px;
		height: 67px;
		position: absolute;
		top: -15px;
		left: -16px;
	}

	.goods-item-img2 {
		width: 342px;
		height: 506px;
		/* height: 238px; */
		background: #eee;
		border-radius: 28px 28px 0px 0px;
	}

	.goods-item-img {
		width: 342px;
		height: 342px;
		/* height: 238px; */
		background: #eee;
		border-radius: 28px 28px 0px 0px;
	}

	.goods-item-mini-img {
		width: 60px;
		height: 60px;
		background: #FFFFFF;
		border: 1px solid #000000;
		border-radius: 50%;
		margin-right: 10px;
		margin-bottom: 8px;
		margin-top: 7px;
		box-sizing: border-box;
	}

	.goods-item-mini-img:nth-child(4n) {
		margin-right: 0px;
	}

	.goos-item-bot {
		width: 342px;
		height: 112px;
		background: rgba(0, 0, 0, 0.04);
		border-radius: 0px 0px 30px 30px;
		padding-top: 13px;
	}

	.title-sec {
		width: 100%;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.goods-item-star {
		width: 16px;
		height: 16px;
		margin-right: 6px;
	}

	.goods-item-title {
		font-size: 26px;
		font-weight: bold;
		color: #000000;
		line-height: 26px;
	}

	.goods-item-price {
		font-size: 24px;
		font-weight: bold;
		color: #E22517;
		line-height: 26px;
	}

	.goods-item-desc {
		width: 310px;
		height: 50px;
		font-size: 19px;
		color: rgba(0, 0, 0, 0.58);
		line-height: 25px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		margin-top: 8px;
		word-break: break-all;
		word-wrap: break-word;
	}

	.time-img {
		width: 235px;
		height: 69px;
		position: absolute;
		top: -20px;
		left: -16px;
	}

	.null-img2 {
		width: 80px;
		height: 64px;
		position: absolute;
		top: 105px;
		left: 0;
		right: 0;
		margin: auto;
	}

	.null-img {
		width: 80px;
		height: 80px;
		position: absolute;
		top: 105px;
		left: 0;
		right: 0;
		margin: auto;
	}

	.time {
		font-size: 24px;
		font-weight: 600;
		letter-spacing: 0;
		color: #FF8B70;
		line-height: 32px;
		position: absolute;
		top: -5px;
		left: 40px;
		left: 45px;
	}

	.icp {
		font-size: 26px;
		color: #FFFFFF;
		background: #000000;
		text-align: center;
		position: fixed;
		bottom: 88px;
		left: 0;
		width: 100%;
		height: 44px;
		z-index: 10
	}

	.zq-model {
		width: 652px;
		height: 810px;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
		z-index: 1001
	}

	.poster {
		width: 652px;
		height: 730px;
	}

	.confirm {
		width: 270px;
		height: 80px;
	}

	.download-app-tips {
		position: fixed;
		bottom: 132px;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 90px;
		background-image: url(../assets/download-btn-bg.png);
		background-size: 750px 90px;
	}

	.close-dl-img {
		width: 30px;
		height: 30px;
		margin-right: 30px;
	}

	.icon-download {
		width: 263px;
		height: 57px;
	}

	.yyzh {
		width: 710px;
		margin: 40px 20px;
		height: auto;
	}
</style>